import { Box, Grid, GridItem, Heading, Image, Text, Button, HStack, Card, Progress, Divider, VStack, List, ListItem, ListIcon, Step, StepIndicator, StepStatus, StepSeparator, StepIcon, StepNumber, Stepper, Code } from "@chakra-ui/react"
import { CheckIcon, ExternalLinkIcon, TimeIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import offerFlow from "../offer-flow.gif"
import createMerchant from "../create-merchant.gif"
import customiseOffer from "../customise-offer.gif"
import selectOffer from "../select-offer.gif"
import click2sign from "../click2sign.gif"
import merchantData from "../merchant-data.gif"
import { AcceptOfferModal, AppContext, CopyButton, CreateMerchantModal, GetOffersModal, Merchant, PaymentSetupModal } from "../App";


interface GuidedSteps {
    title: string,
    description: JSX.Element | string,
    button?: JSX.Element,
    modal?: JSX.Element,
    video?: any,
    progress: number
}

export const ApplicationSteps = (modalOpen: boolean, setModal: any, context: any, setStep: any, setMerchant: any, merchant?: Merchant): GuidedSteps[] => [
    {
        title: `Create a merchant in the sandbox`,
        description: `To be able to get offers, Liberis must first know some information about a merchant. For most partners, we receive this information as part of our "Partner File Transfer" process, in which you send us pseudoanonymised data via SFTP. In the case of the sandbox, we've simplified this process.`,
        button: <Button bg={'green.400'} onClick={() => setModal(true)} w='250px'>Create a merchant</Button>,
        modal: <CreateMerchantModal modalOpen={modalOpen} setModal={setModal} userId={context.userId} completedStep={(merchant: Merchant) => { setStep(1); setMerchant(merchant) }} />,
        video: createMerchant,
        progress: 10
    },
    {
        title: `Get offers for a merchant`,
        description: `Now we've created a merchant, we're able to query the API to gather offers using the limits provided in the previous step.
        The example UI on the right shows the recommended user experience. When a merchant has shown interest in the product, you will invoke our POST / offers endpoint with the merchants company and applicants information.
        We will then perform a number of checks on the merchant and return offers for the maximum limit.
         Note the offer_id returned for each offer, these are used in the next step when a merchant has agreed to which offer they want.`,
        button: <Button bg={'green.400'} onClick={() => setModal(true)} w='250px'>Get offers for a merchant</Button>,
        modal: <GetOffersModal getOffersModalOpen={modalOpen} setGetOffersModalOpen={setModal} merchantId={merchant?.merchantId} isRenewal={false} completedStep={() => { setStep(2) }} />,
        video: offerFlow,
        progress: 20
    },
    {
        title: `Customise an offer for the merchant`,
        description: `If a merchant requires a smaller amount of funding to the maximum they're eligible for, you can repeat the same call as before to our offers /POST endpoint. Simply call the endpoint with an amount_requested set in the payload.`,
        button: <Button bg={'green.400'} onClick={() => setModal(true)} w='300px'>Customise offers for a merchant</Button>,
        modal: <GetOffersModal getOffersModalOpen={modalOpen} setGetOffersModalOpen={setModal} merchantId={merchant?.merchantId} isRenewal={false} amountRequested={25000} completedStep={() => { setStep(3) }} />,
        video: customiseOffer,
        progress: 30
    },
    {
        title: `Accept an offer for the merchant`,
        description: `So we've gathered offers, customised them and a merchant is ready to accept one of them. To do this we simply need the offer_id for their selected offer to be sent to our POST /create/v2/offers/{offer_id}/accept endpoint. Once we receive this request, we'll find the application, stamp it with the offer values and submit to our team for fulfilment!`,
        button: <Button bg={'green.400'} onClick={() => setModal(true)} w='150px'>Accept an offer</Button>,
        modal: <AcceptOfferModal acceptModalOpen={modalOpen} setAcceptModal={setModal} merchant={merchant} completedStep={(merchant?: Merchant) => { setStep(4); setMerchant(merchant) }} />,
        video: selectOffer,
        progress: 40
    },
    {
        title: `Displaying a merchants contracts using Click2Sign`,
        description: <div>After a merchant has accepted an offer, you'll see in your response payload a set of links at the bottom. This section of the response allows us to pass relevant link urls relating to the application process.
            Here we will pass you a field called 'contract_link', which is a direct url to our Click2Sign webpage for the merchant applying.
            Click2Sign allows a merchant to sign their relevant contracts in one click, significantly speeding up their application process and reducing the amount of discourse between Liberis and the merchant. We highly recommend rendering this url in an iframe within your journey, or simply opening the link in a new tab. Documentation on Click2Sign can be found       <Button cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/using-the-click2sign-iframe', '_blank')}>here <ExternalLinkIcon /></Button>
        </div>,
        button: <Button bg={'green.400'} onClick={() => setStep(5)} w='300px'>Continue to next step</Button>,
        modal: <GetOffersModal getOffersModalOpen={modalOpen} setGetOffersModalOpen={setModal} merchantId={merchant?.merchantId} isRenewal={false} amountRequested={25000} completedStep={() => { setStep(5) }} />,
        video: click2sign,
        progress: 50
    },
    {
        title: `Track the status of an application and review its data`,
        description: <div>
            <Text>Once a merchant has accepted an offer, Liberis Create will submit their application to be progressed by our team. As the application is processed internally, the status of it will change, allowing you to display and track its general progression towards being funded.<br></br> </Text>
            <br></br> <Text>To view data for your merchant through Liberis Create, make A GET request to the below url:</Text>
            <br></br>
            <HStack><Code>{context.config?.api.base}/create/v2/merchant?liberisId={merchant?.liberis_id}</Code><CopyButton text={`${context.config?.api.base}/create/v2/merchant?liberisId=${merchant?.liberis_id}`} /> </HStack>

        </div>,
        button: <Button bg={'green.400'} onClick={() => setStep(6)} w='300px'>Continue to next step</Button>,
        modal: <div></div>,
        video: merchantData,
        progress: 70
    },
    {
        title: `Confirm payment setup has been completed for the merchant`,
        description: `When an application passes our underwriting checks we will publish a webhook to your server, containing information about the merchant and any related account numbers. When you receive this notification you'll need to action the setting up of the split or rerouting of a settlement account to a Liberis virtual account, depending on your payment mechanism. If you set up a webhook url in step 1, you should see a webhook message on your server at this point.
        All webhooks with required actions contain an 'action.notify' field that allows you to easily and automatically respond to our webhook once your action has been completed. By calling the URL in this field you will allow us to pass the prefunding checks for the merchant, the final step before funding!`,
        button: <Button bg={'green.400'} onClick={() => setModal(true)} w='300px'>Set up a payment method</Button>,
        modal: <PaymentSetupModal setPaymentSetupModal={setModal} paymentSetupModalOpen={modalOpen} merchant={merchant} completedStep={() => { setStep(7) }} />,
        video: offerFlow,
        progress: 80
    },
    {
        title: `Congratulations, your merchant is funded!`,
        description: `Once the payment method is confirmed and our other prefunding checks have passed, the merchant will be funded same day! You can check on the progress of the merchant using the /create/v2/merchant endpoint from step 6.`,
        progress: 100
    }
]


const GuidedOnboarder = (props: {
    title?: string,
    subTitle?: string,
    objectives: string[],
    timeRequired?: number,
    action?: string,
    actionText?: string,
    guidedSteps: any
}) => {
    const [progress, setProgress] = useState<number>(0);

    return (
        <Box h='100%' >
            <Card border={'none'} boxShadow={'none'} padding='30px'>
                <VStack textAlign={'left'}
                    spacing={8}
                    align="stretch">
                    <Heading size={'lg'}>{props.title}</Heading>
                    <Text>{props.subTitle}</Text>
                    <Divider />
                    <Box>
                        <Heading size={'xs'} marginBottom={'10px'}>Progress</Heading>

                        <Progress w='20%' hasStripe value={progress} />
                    </Box>
                    <HStack><TimeIcon /><Heading size={'sm'}>Time to complete: {props.timeRequired} minutes </Heading></HStack>

                    <Box>
                        <HStack><Heading size={'md'} marginBottom={'10px'}>Learning objective</Heading><Image marginTop={'-10px'} w='40px' src='https://em-content.zobj.net/source/telegram/358/open-book_1f4d6.webp'></Image></HStack>
                        <Objective keyLearnings={props.objectives}></Objective>
                    </Box>

                    <Guider setProgress={setProgress} guidedSteps={props.guidedSteps}></Guider>
                </VStack>
            </Card>
        </Box>
    )
}

export const Guider = (props: { setProgress: any, guidedSteps: any }) => {
    const [modalOpen, setModal] = useState<boolean>(false);
    const [currentStep, setStep] = useState<number>(0);
    const [merchant, setMerchant] = useState<Merchant>();
    const [steps, setSteps] = useState<GuidedSteps[]>([]);

    const context = useContext(AppContext);

    useEffect(() => {
        setSteps(props.guidedSteps(modalOpen, setModal, context, setStep, setMerchant, merchant));
        props.setProgress(steps[currentStep]?.progress)
    }, [modalOpen, merchant])

    return <Box>
        <Card h='100%' border={'none'} boxShadow={'none'}>
            <Grid templateColumns='repeat(7, 1fr)' gap={6}>
                <GridItem padding={'20px'} colSpan={4}>
                    <Stepper index={currentStep} orientation='vertical' height={'100%'} gap='0' colorScheme='green' >
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon />}
                                        incomplete={<StepNumber />}
                                        active={<StepNumber />}
                                    />
                                </StepIndicator>

                                <Box marginBottom={'20px'}>
                                    <VStack textAlign={'left'}
                                        spacing={8}
                                        align="stretch">
                                        <Heading fontSize={'16px'}>{step.title}</Heading>
                                        {currentStep === index && <Text fontWeight={200}>{step.description}</Text>}
                                        {currentStep === index && step.modal}
                                        {currentStep === index && step.button}
                                    </VStack>
                                </Box>

                                <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>

                </GridItem>
                <GridItem colSpan={3}>
                    <Box>
                        <Card h='100%'><Image borderRadius={'5px'} src={steps[currentStep]?.video}></Image></Card>
                        <Card h='100%'></Card>
                    </Box>
                </GridItem>
            </Grid>
        </Card>
    </Box >
}


const Objective = (props: { keyLearnings: string[] }) => {
    return (
        <Box fontWeight={300}>
            <Text margin={'10px 0 10px 0'}>After completing this onboarding task, you will know how to:</Text>
            <List paddingLeft={'10px'} spacing={3}>
                {props.keyLearnings.map((kl: string) => {
                    return <ListItem>
                        <ListIcon as={CheckIcon} color='green.500' />
                        {kl}
                    </ListItem>
                })}

            </List>

        </Box>
    )
}




export default GuidedOnboarder