import React, { createContext, useState, useEffect } from 'react';

type Config = {
  documentation: {
    base: string;
    home: string;
    customiseOffer: string;
    acceptOffer: string;
    acceptOfferReference: string;
    replyWebhooks: string;
    actions: string;
  };
  api: {
    base: string;
    credentials: {
      client_id: string;
      client_secret: string;
      grant_type: string;
    }
    control: {
      deal: string;
      dealCreate: string;
      application: string;
      contract: string;
      merchants: string;
      merchant: string;
      webhooks: string;
      payment: string;
      marketing: string;
      partnerData: string;
      resetStage: string;
      refresh: string
    };
  };
  postmanUrl: string;
  postmanCollection: string;
};

export const ConfigContext = createContext({} as Config);

export const ConfigProvider = ({ children }: { children: any }) => {
  const [config, setConfig] = useState({} as Config);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const isLocalDev = process.env.NODE_ENV === "development";
        console.log(isLocalDev);
        const filename = isLocalDev
          ? "sandbox-ui-local.json"
          : "sandbox-ui.json";
        const response = await fetch(`/settings/${filename}`);

        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }

        const settings = await response.json();
        setConfig(settings);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error instanceof Error) return <div>Error: {error.message}</div>;

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};
