import { Box, Button, Card, Container, Heading, Stack, Image, Text, Center, CardHeader, CardBody, SimpleGrid, FormControl, FormLabel, Input, InputGroup, InputRightAddon, Checkbox, HStack, Wrap, WrapItem, Spinner, Stat, StatHelpText, StatLabel, StatNumber, Divider, StatArrow, Flex, Spacer, Avatar } from "@chakra-ui/react";
import axios from "axios";
import { FC, useContext, useRef, useState } from "react";
import { AppContext } from "../App";
import team from '../devteam.png'
import { useConfig } from "../hooks/useConfig";
import { LineChart, XAxis, YAxis, Legend, Line } from "recharts";
import moment from "moment";
import { usePDF } from "react-to-pdf";
import per from '../per.jpeg';
import ollie from '../ollie.jpeg';
import fin from '../fin.jpeg';


export const downloadCollection = async (url: string) => {
    const res = await axios.get(`${url}`);
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res.data));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "LiberisSandbox.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

const Pricing: FC = () => {


    const industries = [
        { name: "Bar / Pub", multiplier: 1.21 },
        { name: "Car Sales / Garage Services", multiplier: 1.21 },
        { name: "Food / Drink Shop", multiplier: 1.21 },
        { name: "Fashion Retail", multiplier: 1.21 },
        { name: "Hair & Beauty", multiplier: 1.21 },
        { name: "Healthcare", multiplier: 1.21 },
        { name: "Hotel/B&B", multiplier: 1.21 },
        { name: "Restaurant", multiplier: 1.21 },
        { name: "Trade Contractor", multiplier: 1.21 },
        { name: "Retailer", multiplier: 1.21 },
        { name: "Other Services", multiplier: 1.21 },
    ]

    const journey: Product[] = [
        { name: "Core", penetration: 4, weeksDevelopment: 0 },
        { name: "Create", penetration: 6, weeksDevelopment: 3 },
        { name: "POS", penetration: 2, weeksDevelopment: 0 },

    ]

    const repayment: Product[] = [
        { name: "Yes I can split payments", penetration: 0, weeksDevelopment: 2 },
        { name: "No I cannot split payments", penetration: 0, weeksDevelopment: 0 },
    ]

    const turnover: Turnover[] = [
        { name: "1k - 5k", amount: 4000 },
        { name: "5k - 25k", amount: 22000 },
        { name: "25k - 50k", amount: 40000 },
        { name: "50k - 100k", amount: 80000 },
        { name: "100k+", amount: 130000 },
    ]

    const devCostPW = 6750;


    interface Product {
        name: string,
        penetration: number,
        weeksDevelopment: number
    }

    interface Turnover {
        name: string,
        amount: number
    }

    interface Industry {
        name: string,
        multiplier: number
    }

    interface Churn {
        month: string,
        withLiberis: number
        withoutLiberis: number
    }


    const context = useContext(AppContext);
    const config = useConfig()

    const [merchantNumber, setMerchantNumber] = useState<number>(0);
    const [penetration, setPenetration] = useState<number>(0);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

    const [selectedTurnover, setTurnover] = useState<Turnover>();

    const [selectedIndustries, setIndustries] = useState<Industry[]>([]);

    const [averageDeal, setAverageDeal] = useState<number>();
    const [averageFactor, setAverageFactor] = useState<number>(1.2);
    const [commission, setCommission] = useState<number>(15);

    const [numberFunded, setNumberFunded] = useState(0);
    const [totalFunding, setTotalFunding] = useState(0);
    const [revenue, setRevenue] = useState(0);

    const [profit, setProfit] = useState(0);

    const [totalRenewals, setTotalRenewals] = useState(3.3)

    const [totalDeals, setTotalDeals] = useState(0);
    const [totalFundingWithRenewals, setTotalFundingWithRenewals] = useState(0);
    const [totalRevenueWithRenewals, setTotalRevenueWithRenewals] = useState(0);
    const [totalProfitithRenewals, setTotalProfitithRenewals] = useState(0);

    const [calculating, setCalculating] = useState(false);

    const [sized, setSized] = useState(false);

    const [developmentCost, setDevelopmentCost] = useState<number>(0);
    const [developmentWeeks, setDevelopmentWeeks] = useState<number>(0);

    const [churnDefault, setChurnDefault] = useState<boolean>(false);

    const [churn, setChurn] = useState<number>(25.9);

    const [churnMonths, setChurnMonths] = useState<Churn[]>();


    const churnValue = 12.9;
    const churnReduction = Number((churn - churnValue).toFixed(1));


    const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

    function getSum(total: number, num: Product) {
        return total + num.penetration;
    }

    function getFactor(total: number, num: Industry) {
        return total + num.multiplier;
    }

    function getDevelopmentWeeks(total: number, product: Product) {
        return total + product.weeksDevelopment;
    }

    function getDevelopmentCost(total: number, product: Product) {
        return total + (product.weeksDevelopment * devCostPW);
    }

    const GBP = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const anchor = useRef<null | HTMLDivElement>(null);
    const spinnerAnchor = useRef<null | HTMLDivElement>(null);

    const calculate = async () => {
        if (!selectedTurnover) {
            return
        }

        setSized(false);
        setCalculating(true);

        const penetration = selectedProducts.reduce(getSum, 0);

        const factorRate = Number((selectedIndustries.reduce(getFactor, 0) / selectedIndustries.length).toFixed(2));
        setAverageFactor(factorRate);

        const averageDeal = selectedTurnover?.amount * factorRate;
        setAverageDeal(averageDeal);


        setPenetration(penetration);

        const numberFunded = merchantNumber / 100 * penetration;
        setNumberFunded(numberFunded)

        const totalFunded = averageDeal * numberFunded
        setTotalFunding(totalFunded);

        const rev = (totalFunded * factorRate) - totalFunded
        setRevenue(rev)

        setProfit((rev / 100) * commission);

        const lifetimeDeals = numberFunded * totalRenewals

        setTotalDeals(lifetimeDeals);

        const lifetimeFunding = lifetimeDeals * averageDeal;
        setTotalFundingWithRenewals(lifetimeFunding);

        const lifetimeRevenue = (lifetimeFunding * factorRate) - lifetimeFunding
        setTotalRevenueWithRenewals(lifetimeRevenue);

        const lifetimeProfit = (lifetimeRevenue / 100) * commission;

        setTotalProfitithRenewals(lifetimeProfit);

        const cost = selectedProducts.reduce(getDevelopmentCost, 0);

        setDevelopmentCost(cost);

        const devWeeks = selectedProducts.reduce(getDevelopmentWeeks, 0);

        setDevelopmentWeeks(devWeeks);

        createChurnData();

        await sleep(50);

        spinnerAnchor?.current?.scrollIntoView({ behavior: 'smooth' });

        await sleep(2000);

        setCalculating(false);
        setSized(true);

        await sleep(100);

        anchor?.current?.scrollIntoView({ behavior: 'smooth' });

    }

    const createChurnData = () => {
        let months: Churn[] = [];
        const decrementWithoutLiberis = (numberFunded / 100) * (churn / 12)
        const decrementWithLiberis = (numberFunded / 100) * (churnReduction / 12)
        let updatedMerchantsWithoutLiberis = numberFunded;
        let updatedMerchantsWithLiberis = numberFunded;

        for (let i = 0; i <= 12; i++) {
            updatedMerchantsWithoutLiberis -= decrementWithoutLiberis;
            updatedMerchantsWithLiberis -= decrementWithLiberis

            const month = moment().add('month', i).format('MMM');
            months.push({ month, withLiberis: updatedMerchantsWithLiberis, withoutLiberis: updatedMerchantsWithoutLiberis });
        }

        console.log(months);
        setChurnMonths(months);
    }

    const selectJourney = (journey: any) => {
        if (selectedProducts.filter(x => x.name === journey.name).length > 0) {
            const filtered = selectedProducts.filter((x) => x.name !== journey.name);
            setSelectedProducts(filtered);
        }
        else {
            console.log(journey)
            setSelectedProducts([...selectedProducts, journey])
            console.log(selectedProducts)
        }
    }


    const selectIndustry = (industry: Industry) => {
        if (selectedIndustries.filter(x => x.name === industry.name).length > 0) {
            const filtered = selectedIndustries.filter((x) => x.name !== industry.name);
            setIndustries(filtered);
        }
        else {
            console.log(journey)
            setIndustries([...selectedIndustries, industry])
            console.log(selectedProducts)
        }
    }

    const { toPDF, targetRef } = usePDF({ filename: 'liberis-opportunity.pdf' });


    return (<Container p='40px' size={'lg'} maxWidth={'99%'}>
        <Heading textAlign={'center'} color='#black' marginBottom={'50px'} size={'lg'}>Size your integration with Liberis</Heading>
        <br></br>
        <SimpleGrid spacing={20} templateColumns='repeat(auto-fill, minmax(1fr))'>
            <Box textAlign={'center'}>
                <Container maxWidth={'80%'}>
                    <Stack spacing={'40px'}>
                        <Box>
                            <FormControl>
                                <FormLabel>How large is your merchant base?</FormLabel>
                                <InputGroup>
                                    <Input w='150px' type='number' onChange={(e) => setMerchantNumber(Number(e.target.value))} />
                                    <InputRightAddon>
                                        merchants
                                    </InputRightAddon>
                                </InputGroup>
                            </FormControl>
                        </Box>

                        {/* <Box>
                            <HStack>
                                <FormControl w='230px'>
                                    <FormLabel>What is your current churn?</FormLabel>
                                    <InputGroup >
                                        <Input isDisabled={churnDefault} w='150px' type='number' onChange={(e) => setChurn(Number(e.target.value))} />
                                        <InputRightAddon>
                                            %
                                        </InputRightAddon>
                                    </InputGroup>
                                </FormControl>
                                <Checkbox checked={churnDefault} onChange={() => { setChurn(25.9); setChurnDefault(!churnDefault) }} marginTop={'20px'}>
                                    I don't know
                                </Checkbox>
                            </HStack>

                        </Box> */}

                        <Box>
                            <FormLabel>Average monthly turnover</FormLabel>
                            <Stack direction={'row'}>

                                {turnover.map((turnover) => {
                                    return <Card cursor={'pointer'} style={{ padding: '10px' }} className={selectedTurnover?.name === turnover.name ? 'selected' : ''} onClick={() => setTurnover(turnover)}>{turnover.name}</Card>
                                })}

                            </Stack>
                        </Box>

                        <Box>
                            <FormLabel>Journey type</FormLabel>
                            <Flex gap='2' direction={'row'}>

                                {journey.map((journey) => {
                                    return <Box><Card cursor={'pointer'} style={{ padding: '10px' }} className={selectedProducts.filter(x => x.name === journey.name).length > 0 ? 'selected' : ''} onClick={() => selectJourney(journey)}>{journey.name}</Card><Spacer></Spacer></Box>
                                })}

                            </Flex>
                        </Box>


                        <Box>
                            <FormLabel>Can you split payments?</FormLabel>
                            <Flex gap='2' direction={'row'}>

                                {repayment.map((journey) => {
                                    return <Box><Card cursor={'pointer'} style={{ padding: '10px' }} className={selectedProducts.filter(x => x.name === journey.name).length > 0 ? 'selected' : ''} onClick={() => selectJourney(journey)}>{journey.name}</Card><Spacer></Spacer></Box>
                                })}

                            </Flex>
                        </Box>

                        <Box>
                            <FormLabel>Industries</FormLabel>
                            <Wrap>


                                {industries.map((industry) => {
                                    return <WrapItem>
                                        <Card cursor={'pointer'} style={{ padding: '10px' }} className={selectedIndustries.filter(x => x.name === industry.name).length > 0 ? 'selected' : ''} onClick={() => selectIndustry(industry)}>{industry.name}</Card>
                                    </WrapItem>

                                })}

                            </Wrap>
                        </Box>
                    </Stack>
                    <Button
                        margin={'100px 0 100px 0'}
                        className="selected"
                        type='submit'
                        onClick={() => calculate()}
                        isDisabled={!merchantNumber && selectedIndustries.length === 0 && selectedProducts.length === 0 && !selectedTurnover}
                    >
                        Calculate
                    </Button>

                    {
                        calculating && <Box ref={spinnerAnchor} p={'25px'}><Spinner size={'xl'} /></Box>
                    }
                    {
                        sized && <Box ref={anchor}>
                            <Stack ref={targetRef} spacing={20}>
                                <Divider />
                                <Heading color='#black' size={'lg'}>Your opportunity with</Heading>
                                <Image m='0 auto' w='250px' src={'https://liberisproductioncdn.blob.core.windows.net/images/Liberis-logo-full-color.svg'}></Image>
                                <Divider />
                                <Box>
                                    <Heading margin={'0 0 50px 0'} color='#black' size={'md'}>At a glance</Heading>
                                    <Card border={'none'} boxShadow={'none'} marginTop={'10px'} padding={'10px'} w='100%'>

                                        <Stack direction={'row'}>
                                            <Stat>
                                                <StatLabel>Merchant base</StatLabel>
                                                <StatNumber>{merchantNumber}</StatNumber>
                                                <StatHelpText>reach</StatHelpText>
                                            </Stat>

                                            <Stat>
                                                <StatLabel>Average deal size</StatLabel>
                                                <StatNumber>£{averageDeal?.toFixed(0)}</StatNumber>
                                            </Stat>

                                            <Stat>
                                                <StatLabel>Customer penetration</StatLabel>
                                                <StatNumber>{penetration}%</StatNumber>
                                            </Stat>

                                            <Stat>
                                                <StatLabel>Average Factor rate</StatLabel>
                                                <StatNumber>{averageFactor}</StatNumber>
                                            </Stat>
                                        </Stack>
                                    </Card>
                                </Box>
                                <Divider />
                                <Box>
                                    <Heading margin={'30px 0 30px 0'} color='#black' size={'md'}>How much would it cost to develop?</Heading>
                                    <Card border={'none'} boxShadow={'none'} marginTop={'10px'} padding={'10px'} w='100%'>

                                        <Stack direction={'row'}>

                                            <Box>
                                                <Image w='300px' src={team} />
                                            </Box>
                                            <Stat>
                                                <StatLabel>Development time</StatLabel>
                                                <StatNumber>{developmentWeeks === 0 ? 'Nothing!' : `${developmentWeeks} weeks`} </StatNumber>
                                            </Stat>

                                            <Stat>
                                                <StatLabel>Development cost</StatLabel>
                                                <StatNumber>{developmentCost === 0 ? 'Nothing!' : `£${developmentCost}`}</StatNumber>
                                            </Stat>
                                        </Stack>
                                    </Card>
                                </Box>

                                <Divider />

                                <Box>
                                    <Heading margin={'30px 0 30px 0'} color='#black' size={'md'}>How much could you make in commission?</Heading>

                                    <HStack marginTop={'10px'}>
                                        <Card border={'none'} boxShadow={'none'} h='450px' w='50%'>
                                            <CardHeader>
                                                Initials size
                                            </CardHeader>
                                            <CardBody>
                                                <Stack spacing={5}>
                                                    <Stat>
                                                        <StatLabel>Number of merchants funded</StatLabel>
                                                        <StatNumber>{numberFunded.toFixed(0)}</StatNumber>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Total Funding</StatLabel>
                                                        <StatNumber>{GBP.format(totalFunding)}</StatNumber>
                                                        <StatHelpText></StatHelpText>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Total Revenue</StatLabel>
                                                        <StatNumber>{GBP.format(revenue)}</StatNumber>
                                                        <StatHelpText></StatHelpText>
                                                    </Stat>


                                                    <Stat>
                                                        <StatLabel>Your profit</StatLabel>
                                                        <StatNumber color={'green'}>{GBP.format(profit)}</StatNumber>
                                                        <StatHelpText></StatHelpText>
                                                    </Stat>
                                                </Stack>
                                            </CardBody>
                                        </Card>

                                        <Card border={'none'} boxShadow={'none'} h='450px' w='50%'>
                                            <CardHeader>
                                                Lifetime size
                                            </CardHeader>
                                            <CardBody>
                                                <Stack spacing={5}>
                                                    <Stat>
                                                        <StatLabel>Number of deals</StatLabel>
                                                        <StatNumber>{totalDeals.toFixed(0)}</StatNumber>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Total funding</StatLabel>
                                                        <StatNumber>{GBP.format(totalFundingWithRenewals)}</StatNumber>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Total revenue</StatLabel>
                                                        <StatNumber>{GBP.format(totalRevenueWithRenewals)}</StatNumber>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Your profit</StatLabel>
                                                        <StatNumber color={'green'}>{GBP.format(totalProfitithRenewals)}</StatNumber>
                                                    </Stat>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </HStack>
                                </Box>
                                <Divider />
{/* 
                                <Box>
                                    <Heading margin={'30px 0 30px 0'} color='#black' size={'md'}>What's the effect on my churn?</Heading>
                                    <Card border={'none'} boxShadow={'none'} p='10px'>
                                        <HStack>
                                            <Box>
                                                <Stack spacing={10}>
                                                    <Stat>
                                                        <StatLabel>This means your churn rate could be</StatLabel>
                                                        <StatNumber>{churnReduction}%</StatNumber>
                                                        <StatHelpText>
                                                            <StatArrow type='decrease' />
                                                            12.9%
                                                        </StatHelpText>
                                                    </Stat>

                                                    <Stat>
                                                        <StatLabel>Annual merchant retention</StatLabel>
                                                        <StatNumber>{(merchantNumber / 100 * churnValue).toFixed(0)} merchants</StatNumber>
                                                    </Stat>
                                                </Stack>
                                            </Box>
                                            <LineChart width={730} height={250} data={churnMonths}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Legend />
                                                <Line type="monotone" dot={false} name='With Liberis' dataKey="withLiberis" stroke="#8884d8" strokeWidth={'2'} />
                                                <Line type="monotone" dot={false} name='Without Liberis' dataKey="withoutLiberis" stroke="#82ca9d" strokeWidth={'2'} />
                                            </LineChart>
                                        </HStack>
                                    </Card>
                                </Box> */}

                                <Divider />
                                <Box>
                                    <Heading margin={'30px 0 30px 0'} color='#black' size={'md'}>Interested? Get in touch!</Heading>
                                    <Card textAlign={'center'} border={'none'} boxShadow={'none'} p='10px'>
                                        <Center>
                                            <HStack>
                                                <Center py={6}>
                                                    <Box
                                                        maxW={'320px'}
                                                        w={'full'}

                                                        boxShadow={'md'}
                                                        rounded={'lg'}
                                                        p={6}
                                                        textAlign={'center'}>
                                                        <Avatar
                                                            size={'xl'}
                                                            src={
                                                                per
                                                            }
                                                            mb={4}
                                                            pos={'relative'}
                                                            _after={{
                                                                content: '""',
                                                                w: 4,
                                                                h: 4,
                                                                bg: 'green.300',
                                                                border: '2px solid white',
                                                                rounded: 'full',
                                                                pos: 'absolute',
                                                                bottom: 0,
                                                                right: 3,
                                                            }}
                                                        />
                                                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                                                            Per Dahlqvist
                                                        </Heading>
                                                        <Text fontWeight={600} color={'gray.500'} mb={4}>
                                                            Business Development
                                                        </Text>
                                                        <Text
                                                            textAlign={'center'}
                                                            px={3}>
                                                            per.dahlqvist@liberis.com
                                                        </Text>
                                                    </Box>
                                                </Center>
                                                <Center py={6}>
                                                    <Box
                                                        maxW={'320px'}
                                                        w={'full'}

                                                        boxShadow={'md'}
                                                        rounded={'lg'}
                                                        p={6}
                                                        textAlign={'center'}>
                                                        <Avatar
                                                            size={'xl'}
                                                            src={
                                                                ollie
                                                            }
                                                            mb={4}
                                                            pos={'relative'}
                                                            _after={{
                                                                content: '""',
                                                                w: 4,
                                                                h: 4,
                                                                bg: 'green.300',
                                                                border: '2px solid white',
                                                                rounded: 'full',
                                                                pos: 'absolute',
                                                                bottom: 0,
                                                                right: 3,
                                                            }}
                                                        />
                                                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                                                            Ollie Hoghton
                                                        </Heading>
                                                        <Text fontWeight={600} color={'gray.500'} mb={4}>
                                                            Enterprise Sales
                                                        </Text>
                                                        <Text
                                                            textAlign={'center'}
                                                            px={3}>
                                                            ollie.hoghton@liberis.com
                                                        </Text>
                                                    </Box>
                                                </Center>
                                                <Center py={6}>
                                                    <Box
                                                        maxW={'320px'}
                                                        w={'full'}

                                                        boxShadow={'md'}
                                                        rounded={'lg'}
                                                        p={6}
                                                        textAlign={'center'}>
                                                        <Avatar
                                                            size={'xl'}
                                                            src={
                                                                fin
                                                            }
                                                            mb={4}
                                                            pos={'relative'}
                                                            _after={{
                                                                content: '""',
                                                                w: 4,
                                                                h: 4,
                                                                bg: 'green.300',
                                                                border: '2px solid white',
                                                                rounded: 'full',
                                                                pos: 'absolute',
                                                                bottom: 0,
                                                                right: 3,
                                                            }}
                                                        />
                                                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                                                            Finlay Craig
                                                        </Heading>
                                                        <Text fontWeight={600} color={'gray.500'} mb={4}>
                                                            Enterprise Sales
                                                        </Text>
                                                        <Text
                                                            textAlign={'center'}
                                                            px={3}>
                                                            finlay.craig@liberis.com
                                                        </Text>
                                                    </Box>
                                                </Center>
                                            </HStack>
                                        </Center>
                                    </Card>
                                </Box>
                            </Stack>
                            <Button marginTop={'100px'} className="selected"
                                type='submit' onClick={() => toPDF()}>Download PDF</Button>
                        </Box>
                    }
                </Container>
            </Box >
        </SimpleGrid >
    </Container >)

}


export default Pricing
