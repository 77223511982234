import { Text, Image, Button, Box, Card, ChakraProvider, Container, Heading, Stack, Center, Divider, Grid, GridItem, Stepper, Step, StepDescription, StepIcon, StepIndicator, StepSeparator, StepStatus, StepTitle, useSteps, CardHeader, CardBody, FormControl, FormLabel, Checkbox, Slider, SliderFilledTrack, SliderThumb, SliderTrack, NumberInput, NumberInputField, InputGroup, InputRightElement, Spinner, Tooltip, Skeleton, InputLeftAddon, InputRightAddon, SkeletonCircle, CardFooter, Input, InputLeftElement, HStack, Stat, StatLabel, StatNumber, WrapItem, Wrap, SimpleGrid, Modal, ModalOverlay, ModalBody, ModalContent, AbsoluteCenter, Tab, TabList, TabPanel, TabPanels, Tabs, ModalCloseButton } from "@chakra-ui/react";
import axios from "axios";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AppContext, CustomInput, baseApplication, formatCurrency } from "../App";
import { ReactComponent as Check } from '../purplecheck.svg';
import homeart from '../homeart.svg'
import bgslide from '../bgslide.svg'
import { ReactComponent as Cards } from '../cards.svg';
import video from '../video.svg'
import merchcards from '../merchcards.svg'
import { ReactComponent as Store } from '../store.svg';
import checkbox from '../checkbox.svg'
import oppocards from '../oppocards.svg'
import customer from '../customer.svg'
import barcircle from '../barcircle.svg'
import progressone from '../progressone.svg'
import progresstwo from '../progresstwo.svg'
import progressthree from '../progressthree.svg'
import progressfour from '../progressfour.svg'
import liberiscombinedlogo from '../liberiscombinedlogo.svg'
import merchantimage from '../merchantimage.svg'
import questions from '../questions.svg'
import landingcard from '../landingcard.svg'
import explainers from '../explainers.svg'
import storecircle from '../storecircle.svg'
import check from '../check.svg'
import personcircle from '../personcircle.svg'
import approvalcard from '../approvalcard.svg'
import edit from '../edit.svg'
import dollar from '../dollar.svg'
import stepone from '../stepone.svg'
import steptwo from '../steptwo.svg'
import footer from '../footer.svg'
import gauge from '../gauge.svg'
import merchantonphone from '../merchantonphone.svg'
import { ReactComponent as Selected } from '../selected.svg';
import { AddIcon, ArrowForwardIcon, EditIcon, InfoOutlineIcon, DownloadIcon, CheckCircleIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { debounce } from 'lodash';
import { useConfig } from "../hooks/useConfig";
import moment from "moment";
import { useDropzone } from "react-dropzone";

enum Page {
    Configuration,
    Landing,
    BankStatement,
    Review,
    BusinessInfo,
    Offers,
    Contract,
    Thankyou
}

interface JourneyConfig {
    logoUrl?: string,
    partnerName?: string,
    primary?: string,
    hasShareholderInfo: boolean,
    hasTransactionInfo: boolean,
    enableBankStatements: boolean,
    newJourney: boolean
}

interface BankStatementReport {
    bank_names: string[],
    bank_address: string[],
    merchant_names: string,
    addresses: string[],
    statement_start_date: string,
    revenue: [
        {
            month: string,
            total: number
        },
    ]
}

const ExampleJourney: FC = () => {

    const [page, setPage] = useState<Page>(Page.Landing);
    const [journeyConfig, setJourneyConfig] = useState<JourneyConfig>();
    const [bankStatements, setBankStatements] = useState<BankStatementReport>()
    const [selectedOffer, setSelectedOffer] = useState<Offer>();
    const [currency, setCurrency] = useState<string>('GBP');

    useEffect(() => {
        const journeyConfig = localStorage.getItem('journeyconfig');
        journeyConfig ? setJourneyConfig(JSON.parse(journeyConfig)) : setPage(Page.Configuration);

        const location = window.location.toString().replace('#', '/');
        const currencyParam = new URL(location).searchParams.get('currency');

        if (currencyParam && currencyParam !== 'undefined') {
            setCurrency(currencyParam);
        }
    }, [currency])


    return <ChakraProvider>
        {page === Page.Configuration && <JourneyConfigurator setConfig={setJourneyConfig} setPage={setPage} />}
        {page === Page.BankStatement && <BankStatementImport setPage={setPage} config={journeyConfig} setBankData={setBankStatements} />}
        {page === Page.Landing &&
            (journeyConfig?.newJourney ? <LandingLatest config={journeyConfig} setPage={setPage} currency={currency} ></LandingLatest>
                : <Landing config={journeyConfig} setPage={setPage} currency={currency} />)}

        {page === Page.Review && (journeyConfig?.newJourney ? <ReviewLatest config={journeyConfig} setPage={setPage} bankStatements={bankStatements} ></ReviewLatest> : <Review config={journeyConfig} setPage={setPage} bankStatements={bankStatements} />)}
        {page === Page.BusinessInfo && <BusinessInfo config={journeyConfig} setPage={setPage} currency={currency} />}
        {page === Page.Offers && (journeyConfig?.newJourney ? <OffersLatest setOfferId={setSelectedOffer} setPage={setPage} config={journeyConfig} currency={currency} /> : <Offers setOfferId={setSelectedOffer} setPage={setPage} config={journeyConfig} currency={currency} />)}
        {page === Page.Contract && (journeyConfig?.newJourney ? <ContractsLatest offer={selectedOffer} setPage={setPage} config={journeyConfig} /> : <Contracts offerId={selectedOffer?.offer_id} setPage={setPage} config={journeyConfig} />)}
        {page === Page.Thankyou && (journeyConfig?.newJourney ? <ThankyouLatest offer={selectedOffer} config={journeyConfig} /> : <Thankyou offer={selectedOffer} config={journeyConfig} />)}
    </ChakraProvider >
}

const ProgressHeader = (props: { step: number, percentage: number, config?: JourneyConfig }) => {

    const primary = props.config?.primary ?? '#6437E2';

    const steps = [
        { title: 'Details', },
        { title: 'Your offer', },
        { title: 'Contracts', },
        { title: 'Finish', },
    ]

    const { activeStep } = useSteps({
        index: props.step ?? 0,
        count: steps.length,
    })

    return (<Box marginBottom={'20px'}>

        <Grid templateColumns='repeat(5, 1fr)' gap={10} marginBottom={'20px'}>
            <GridItem colSpan={3}>
                <Stack paddingTop={'50px'}>
                    <Stepper w={'100%'} colorScheme='blackAlpha' index={activeStep}>
                        {steps.map((step, index) => (
                            <Step key={index}>

                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon />}
                                        incomplete={<Box></Box>}
                                        active={<Box bg={primary} w='100px' color='white' fontSize={'14px'} fontWeight={'bold'} textAlign={'center'} borderRadius={'100px'} h={'100%'}>...</Box>}
                                    />
                                </StepIndicator>
                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                </Box>

                                <StepSeparator />
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </GridItem>
            <GridItem colSpan={2}>
                <Center>
                    <Stack direction={'row'} spacing={10}>
                        <Card paddingTop={'25px'} textAlign={'center'} h={'117.72px'} w='135.36px' bg={props.percentage === 100 ? 'black' : 'white'} color={props.percentage === 100 ? 'white' : 'black'}>
                            <Heading>{props.percentage}%</Heading>
                            <Text>completed</Text>
                        </Card>
                        <Box textAlign={'center'} fontSize={'14px'}>
                            {props.config?.logoUrl && <Box w={'120px'} p={0}><Image h='60px' src={props.config?.logoUrl}></Image></Box>}

                            {!props.config?.logoUrl && <Box p={2} color={'white'} bg='gray'>PARTNER LOGO</Box>}


                            <Text>Need Help?</Text>
                            <b >0303 313 0070</b>
                        </Box>
                    </Stack>
                </Center>
            </GridItem>
        </Grid>
        <Divider />
    </Box>)
}

interface Offer {
    offer_id: string,
    offer_status: string,
    description: string,
    split_percentage: number,
    term_length: number,
    repayment_amount: number,
    funded_amount: number,
    factor_rate: number,
    currency: string,
}

const Offers = (props: { setPage: any, setOfferId: any, config?: JourneyConfig, currency: string }) => {

    interface Offers {
        products: [
            {
                product_id: string,
                name: string,
                description: string,
                decision: string,
                repayment_mechanism: string,
                limits: {
                    minimum: number,
                    maximum: number
                },
                offers: Offer[]
            }
        ],
        expiresAt: Date
    }

    const [offers, setOffers] = useState<Offers>()
    const [amountRequested, setAmountRequested] = useState<number>(0)
    const [selectedOffer, setSelectedOffer] = useState<Offer>()
    const [loading, setLoading] = useState<boolean>(true);
    const [sliderValue, setSliderValue] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    const [showTooltip, setShowTooltip] = useState(false)
    const [outOfBounds, setoutOfBounds] = useState(false)
    const [customSplit, setCustomSplit] = useState(false)
    const [customSplitValue, setCustomSplitValue] = useState(20)
    const config = useConfig()
    const primary = props.config?.primary ?? '#6437E2'

    const getOffers = async (currency: string, amountReq?: number, offs?: Offers) => {
        if (amountReq) {
            console.dir(offs);
            console.log(amountReq, Number(offs?.products[0]?.limits?.minimum), Number(offs?.products[0]?.limits?.maximum))
            if (amountReq >= Number(offs?.products[0]?.limits?.minimum) && amountReq <= Number(offs?.products[0]?.limits?.maximum)) {
                console.log('right')
                setAmountRequested(amountReq);
                setoutOfBounds(false);
            }
            else {
                console.log('less')
                setoutOfBounds(true);
                return;
            }
        }
        const location = window.location.toString().replace('#', '/');
        const merchantId = new URL(location).searchParams.get('merchantId');

        setLoading(true);
        const token = await axios.post(`${config?.api.base}/token`, {
            client_id: config?.api.credentials.client_id,
            client_secret: config?.api.credentials.client_secret,
            grant_type: config?.api.credentials.grant_type,
        })

        const res = await axios.post(`${config?.api.base}/offers`, baseApplication(currency, merchantId ?? '', undefined, false, amountReq ?? undefined, false), { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
        if (res.data) {
            setOffers(res.data);
            setSelectedOffer(undefined);
            setCustomSplit(false);

            if (!amountReq) {
                console.log('setting');
                setAmountRequested(res.data.products[0].limits.maximum);
                setInputValue(res.data.products[0].limits.maximum)
            }

            setLoading(false);
        }
    }

    const debouncedSearch = useRef(
        debounce(getOffers, 1000)
    );

    const uppercase = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const handleChange = (value: number) => {
        debouncedSearch.current(props.currency, value, offers);
    };


    useEffect(() => {
        if (!offers) {
            console.log(amountRequested);
            debouncedSearch.current(props.currency ?? 'GBP', amountRequested, offers);

            return () => {
                debouncedSearch.current.cancel();
            };
        }
    }, [offers])

    const format = (currency: string, val: string) => `${formatCurrency(currency)}${val}`


    return (<Container marginTop={'2%'} maxW={'6xl'}>
        <ProgressHeader step={1} percentage={50} config={props.config}></ProgressHeader>
        {!offers?.products && <Stack spacing={8}>
            <Heading size={'lg'} fontWeight={500}>We're getting your offer now</Heading>
            <Text>We’ll provide the best possible deal based on your average monthly revenue with {props.config?.partnerName}.</Text>
            <Center marginTop={'100px'}>
                <Box position='absolute' className="circle"></Box>
                <Box w='250px' color={primary}><Store /></Box>
            </Center>
            <Center marginTop={'50px'}>
                <Heading size={'md'}>Gathering your offer</Heading>
            </Center>
            <Center>
                <Text>If this page fails to load <b>press here </b>to retry.</Text>
            </Center>
        </Stack>}

        {offers?.products && <Stack spacing={8}>
            <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                <GridItem colSpan={2} p={10}>
                    <Stack>
                        <Heading marginBottom={'50px'} fontWeight={'400'}>Your quote</Heading>
                        <Divider marginBottom={'10px'} />
                        <Heading size={'sm'}>Select funding amount</Heading>
                        <Text fontSize={'14px'} color={'#404A70'}>Select the amount of funding that you require.</Text>
                        <Slider margin={'30px 0 30px 0'} onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)} onChange={(e) => { handleChange(e); setSliderValue(e); setInputValue(e) }} defaultValue={offers.products[0].limits.maximum} max={offers.products[0].limits.maximum} min={offers.products[0].limits.minimum} step={1000} colorScheme="purple" aria-label='slider-ex-1'>
                            <SliderTrack bg={primary}>
                                <SliderFilledTrack bg={primary} />
                            </SliderTrack>
                            <Tooltip
                                hasArrow
                                color='white'
                                placement='top'
                                isOpen={showTooltip}
                                label={`${sliderValue}`}
                            >
                                <SliderThumb bg={primary} boxSize={6}>
                                    <Box bg={primary} />
                                </SliderThumb>
                            </Tooltip>

                        </Slider>

                        <InputGroup w={'200px'}>
                            <InputRightElement
                                pointerEvents='none'
                                color='black'
                                fontSize='1.2em'
                                children={<EditIcon />}
                            />
                            <NumberInput
                                onChange={(valueString) => { setInputValue(Number(valueString)); setSliderValue(Number(valueString)); handleChange(Number(valueString)) }}
                                max={offers.products[0].limits.maximum}
                                value={inputValue}
                                defaultValue={offers.products[0].limits.maximum.toString()}
                                w={'200px'}

                            >
                                <NumberInputField border={`2px solid ${primary}`}></NumberInputField>
                            </NumberInput>
                        </InputGroup>
                        {outOfBounds && <Text fontSize={'14px'} color={'red'}>Enter a value between {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.minimum.toString())} and {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.maximum.toString())} </Text>}

                        <Divider marginTop={'80px'} />
                        <Heading size={'sm'}>Select your payment terms</Heading>
                        <Text fontSize={'14px'} color={'#404A70'}>This is the percentage of daily sales that will go towards your funding payments. Selecting a higher percentage of daily sales typically gives you a lower fixed fee*.</Text>

                        <Stack marginTop={'30px'} spacing={8}>
                            {offers.products[0].offers.filter((x: Offer) => x.split_percentage < 20 && x.split_percentage % 5 === 0).map((offer) => {
                                return <Stack key={offer.offer_id} direction={'row'}>
                                    {loading && <SkeletonCircle isLoaded={!loading} size='5' />}
                                    <Skeleton w='200px' key={offer.offer_id} isLoaded={!loading}>
                                        <Stack cursor={'pointer'} direction={'row'} onClick={() => { setSelectedOffer(offer); setCustomSplit(false) }}>
                                            <Box >{
                                                selectedOffer?.offer_id === offer.offer_id ?
                                                    <Box style={{ color: primary }}><Selected /></Box> : <Image src={checkbox} />
                                            } </Box>
                                            <Text><b>{offer.split_percentage}%</b> of daily sales</Text>
                                        </Stack>
                                    </Skeleton>
                                </Stack>
                            })}
                            <Stack direction={'row'}>
                                {loading && <SkeletonCircle isLoaded={!loading} size='5' />}
                                <Skeleton w='350px' isLoaded={!loading}>
                                    <Stack cursor={'pointer'} direction={'row'} onClick={() => { setCustomSplit(true); setSelectedOffer(offers.products[0].offers.find(y => y.split_percentage === customSplitValue)) }}>
                                        <Box >{
                                            customSplit ?
                                                <Box style={{ color: primary }}><Selected /></Box> : <Image src={checkbox} />
                                        } </Box>
                                        <InputGroup marginTop={'-10px'} w={'200px'}>
                                            <InputLeftAddon bg='' textAlign={'center'} children={<Box fontSize={'10px'}><Text>1%</Text><Text>min</Text></Box>} />

                                            <NumberInput
                                                onChange={(valueString) => { setCustomSplitValue(Number(valueString)); setSelectedOffer(offers.products[0].offers.find(y => y.split_percentage === Number(valueString))) }}
                                                value={(customSplitValue.toString())}
                                                defaultValue={(customSplitValue.toString())}
                                                w={'200px'}
                                                textAlign={'center'}

                                            >
                                                <NumberInputField textAlign={'center'} borderRadius={0}></NumberInputField>
                                            </NumberInput>
                                            <InputRightAddon textAlign={'center'} children={<Box fontSize={'10px'}><Text>29%</Text><Text>max</Text></Box>} />
                                        </InputGroup>

                                    </Stack>
                                </Skeleton>
                            </Stack>
                        </Stack>
                    </Stack>
                </GridItem>
                <GridItem top={10} marginTop={'0%'} borderRadius={'15px'} colSpan={2} p={10}>
                    <Card minW={'475px'} boxShadow={'0px 0px 24px 0px #16103126'} borderRadius={'15px'} position={'sticky'} top={0} >
                        <Stack padding={'30px'} spacing={5}>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Heading fontWeight={'700'} size='md'>How does it work?</Heading>
                            </Skeleton>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Stack direction={'row'}>
                                    <Image src={barcircle}></Image>
                                    <Text><b>{selectedOffer?.split_percentage}%</b> of daily sales will be paid to Liberis.</Text>
                                </Stack>
                                <Bars selectedOffer={selectedOffer} config={props.config} />
                                <Text textAlign={'center'} color='#404A70' fontSize={'10px'}>Figures are representative examples only.</Text>
                            </Skeleton>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Heading fontSize={'18px'} fontWeight={'700'} size='md'>Your quote</Heading>
                            </Skeleton>
                        </Stack>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text>Payment terms</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 20px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text>Percentage you pay (%)</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'}>{selectedOffer?.split_percentage ? `${selectedOffer?.split_percentage}% daily sales` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>

                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text>Estimated completion</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'}>{selectedOffer?.term_length ? `${selectedOffer?.term_length} months` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text>Total funding deal</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 10px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text>Funding amount</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'}>{selectedOffer?.funded_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.funded_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text>Fixed fee</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'}>{selectedOffer ? `${formatCurrency(selectedOffer.currency, Number(selectedOffer?.repayment_amount) - Number(selectedOffer?.funded_amount))}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Divider />
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text>Total to pay</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'}>{selectedOffer?.repayment_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.repayment_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                        <Box borderBottomRadius={'15px'} p={7} textAlign={'center'} bg='white'>
                            <Button isDisabled={!selectedOffer} onClick={() => { props.setOfferId(selectedOffer); props.setPage(Page.Contract); window.scrollTo(0, 0); }} fontWeight={'400'} bg={primary} color={selectedOffer ? 'white' : '#0E0B23'} borderRadius={'25px'} w='300px' h='50px'>Select quote<ArrowForwardIcon marginLeft={'10px'} /></Button>
                        </Box>
                    </Card>
                </GridItem>
            </Grid >

        </Stack >}

    </Container >)
}

const OffersLatest = (props: { setPage: any, setOfferId: any, config?: JourneyConfig, currency: string }) => {

    interface Offers {
        products: [
            {
                product_id: string,
                name: string,
                description: string,
                decision: string,
                repayment_mechanism: string,
                limits: {
                    minimum: number,
                    maximum: number
                },
                offers: Offer[]
            }
        ],
        expiresAt: Date
    }

    const [offers, setOffers] = useState<Offers>()
    const [amountRequested, setAmountRequested] = useState<number>(0)
    const [selectedOffer, setSelectedOffer] = useState<Offer>()
    const [loading, setLoading] = useState<boolean>(true);
    const [sliderValue, setSliderValue] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    const [showTooltip, setShowTooltip] = useState(false)
    const [outOfBounds, setoutOfBounds] = useState(false)
    const [customSplit, setCustomSplit] = useState(false)
    const [customSplitValue, setCustomSplitValue] = useState(20)
    const config = useConfig()
    const primary = props.config?.primary ?? (props.config?.newJourney ? '#0E0B23' : '#6437E2');

    const getOffers = async (currency: string, amountReq?: number, offs?: Offers) => {
        if (amountReq) {
            console.dir(offs);
            console.log(amountReq, Number(offs?.products[0]?.limits?.minimum), Number(offs?.products[0]?.limits?.maximum))
            if (amountReq >= Number(offs?.products[0]?.limits?.minimum) && amountReq <= Number(offs?.products[0]?.limits?.maximum)) {
                console.log('right')
                setAmountRequested(amountReq);
                setoutOfBounds(false);
            }
            else {
                console.log('less')
                setoutOfBounds(true);
                return;
            }
        }
        const location = window.location.toString().replace('#', '/');
        const merchantId = new URL(location).searchParams.get('merchantId');

        setLoading(true);
        const token = await axios.post(`${config?.api.base}/token`, {
            client_id: config?.api.credentials.client_id,
            client_secret: config?.api.credentials.client_secret,
            grant_type: config?.api.credentials.grant_type,
        })

        const res = await axios.post(`${config?.api.base}/offers`, baseApplication(currency, merchantId ?? '', undefined, false, amountReq ?? undefined, props.config?.newJourney), { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
        if (res.data) {
            setOffers(res.data);
            setSelectedOffer(res.data.products[0].offers[0]);
            setCustomSplit(false);

            if (!amountReq) {
                console.log('setting');
                setAmountRequested(res.data.products[0].limits.maximum);
                setInputValue(res.data.products[0].limits.maximum)
            }

            setLoading(false);
        }
    }

    const debouncedSearch = useRef(
        debounce(getOffers, 1000)
    );

    const uppercase = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const handleChange = (value: number) => {
        debouncedSearch.current(props.currency, value, offers);
    };


    useEffect(() => {
        if (!offers) {
            console.log(amountRequested);
            debouncedSearch.current(props.currency ?? 'GBP', amountRequested, offers);

            return () => {
                debouncedSearch.current.cancel();
            };
        }
    }, [offers])

    const format = (currency: string, val: string) => `${formatCurrency(currency)}${val}`


    return (
        <Box>
            <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>

            <Container marginTop={'2%'} maxW={'lg'} minH={'100vh'}>
                {!offers?.products &&

                    <Modal isCentered isOpen={true} onClose={() => { }}>
                        <ModalOverlay />
                        <ModalContent height={'473px'} width={'480px'}>
                            <ModalBody>
                                <AbsoluteCenter textAlign={'center'}>
                                    <Stack spacing={1}>
                                        <Text margin={'0 auto'} fontSize={'18px'} fontWeight={'600'}>Loading your quote</Text>
                                        <Text margin={'0 auto'} color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>We're personalizing your quote</Text>
                                        <Spinner margin={'0 auto'} marginTop={'20px'} />
                                    </Stack>
                                </AbsoluteCenter>
                            </ModalBody>

                        </ModalContent>
                    </Modal>

                }

                {
                    offers?.products && <Stack spacing={8}>


                        <Image margin={'0 auto'} src={progresstwo} />

                        <Stack>
                            <Text margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Now choose your funding amount</Text>

                            <Text margin={'0 auto'} textAlign={'center'} fontSize={'14px'} fontWeight={'400'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                            <Stack spacing={4}>
                                <Image marginTop={'50px'} w={'70px'} src={stepone}></Image>
                                <Text fontSize={'18px'} fontWeight={'700'}>Select funding amount</Text>
                                <InputGroup w={'100%'} borderRadius={'8px'} border={`1px solid ${primary}`}>
                                    <InputRightElement
                                        pointerEvents='none'
                                        color='black'
                                        fontSize='1.2em'
                                        children={<Image src={edit} />}
                                    />
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='black'
                                        fontSize='1.2em'
                                        children={<Image src={dollar} />}
                                    />
                                    <NumberInput
                                        onChange={(valueString) => { setInputValue(Number(valueString)); setSliderValue(Number(valueString)); handleChange(Number(valueString)) }}
                                        max={offers.products[0].limits.maximum}
                                        value={inputValue}
                                        defaultValue={offers.products[0].limits.maximum.toString()}
                                        w={'100%'}
                                        marginLeft={'35px'}
                                        variant={'flushed'}
                                    >
                                        <NumberInputField border={'none'}></NumberInputField>
                                    </NumberInput>
                                </InputGroup>
                                {outOfBounds && <Text fontSize={'14px'} color={'red'}>Enter a value between {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.minimum.toString())} and {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.maximum.toString())} </Text>}
                            </Stack>

                            <Slider margin={'30px 0 0px 0'} onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)} onChange={(e) => { handleChange(e); setSliderValue(e); setInputValue(e) }} defaultValue={offers.products[0].limits.maximum} max={offers.products[0].limits.maximum} min={offers.products[0].limits.minimum} step={1000} colorScheme="purple" aria-label='slider-ex-1'>
                                <SliderTrack bg={primary}>
                                    <SliderFilledTrack bg={primary} />
                                </SliderTrack>
                                <Tooltip
                                    hasArrow
                                    color='white'
                                    placement='top'
                                    isOpen={showTooltip}
                                    label={`${sliderValue}`}
                                >
                                    <SliderThumb bg={primary} boxSize={6}>
                                        <Box bg={primary} />
                                    </SliderThumb>
                                </Tooltip>

                            </Slider>

                            <Box pos={'relative'}>
                                <Box pos={'absolute'} left={0}>
                                    <Text color={'#6C728A'} size={'14px'} fontWeight={'400'}>Min: ${offers.products[0].limits.minimum}</Text>
                                </Box>
                                <Box pos={'absolute'} right={0}>
                                    <Text color={'#6C728A'} size={'14px'} fontWeight={'400'}>Max: ${offers.products[0].limits.maximum}</Text>
                                </Box>
                            </Box>

                            <Stack spacing={4}>
                                <Image marginTop={'50px'} w={'70px'} src={steptwo}></Image>
                                <Text fontSize={'18px'} fontWeight={'700'}>Choose your offer</Text>
                            </Stack>

                            <Stack margin={'0 auto'} direction={'row'} marginTop={'10px'} spacing={7}>
                                {offers.products[0].offers.map((offer, index) => {
                                    return <Stack key={offer.offer_id} direction={'row'}>
                                        <Skeleton key={offer.offer_id} isLoaded={!loading}>
                                            <Stack cursor={'pointer'} onClick={() => { setSelectedOffer(offer) }}>
                                                <Box>
                                                    <Text fontWeight={'700'} color={'#404A70'} fontSize={'14px'}>OFFER {index + 1}:</Text>
                                                    <Text fontWeight={'700'} color={'#0E0B23'} fontSize={'14px'}>{offer.description[0].toUpperCase() + offer.description.slice(1)}</Text>
                                                </Box>
                                                <Box p='20px' borderRadius={'8px'} border={'1px solid #00000063'} bg={selectedOffer?.offer_id === offer.offer_id ? '#0E0B23' : 'white'} color={selectedOffer?.offer_id === offer.offer_id ? 'white' : 'black'}>
                                                    <Stack>
                                                        <Box>
                                                            <Text fontWeight={'600'} fontSize={'14px'}>Fixed fee</Text>
                                                            <Text fontWeight={'600'} fontSize={'14px'} color={selectedOffer?.offer_id === offer.offer_id ? '#D9DBE2' : '#525252'}>${offer.repayment_amount - offer.funded_amount}</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text fontWeight={'600'} fontSize={'14px'}>Split (daily %)</Text>
                                                            <Text fontWeight={'600'} fontSize={'14px'} color={selectedOffer?.offer_id === offer.offer_id ? '#D9DBE2' : '#525252'}>{offer.split_percentage}</Text>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Skeleton>
                                    </Stack>
                                })}
                            </Stack>
                        </Stack>


                        <Card border={'1px solid #00000063'} borderRadius={'20px'} top={0} >

                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>

                                <Tabs margin={'0 auto'} variant='soft-rounded' colorScheme='gray'>
                                    <Stack padding={'20px'} spacing={5}>
                                        <TabList textAlign={'center'}>
                                            <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >Review quote terms</Tab>
                                            <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >How does it work?</Tab>
                                        </TabList>
                                    </Stack>
                                    <TabPanels>
                                        <TabPanel p={'30px'}>
                                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                                <Text fontSize={'19px'} fontWeight={'400'} size='md'>Quote terms</Text>
                                                <Text fontWeight={'400'} fontSize={'15px'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                                            </Skeleton>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>



                            </Skeleton>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Payment terms</Text></Box>
                            </Skeleton>
                            <Box padding={'20px 20px 20px 30px'}>
                                <Stack spacing={5}>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={2}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Split (%)</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={2}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.split_percentage ? `${selectedOffer?.split_percentage}% of daily sales` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>

                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={2}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Estimated completion</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={2}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.term_length ? `${selectedOffer?.term_length} months` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                </Stack>
                            </Box>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Total funding amount</Text></Box>
                            </Skeleton>
                            <Box padding={'20px 20px 10px 30px'}>
                                <Stack spacing={5}>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Funding amount</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.funded_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.funded_amount)}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Fixed fee</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer ? `${formatCurrency(selectedOffer.currency, Number(selectedOffer?.repayment_amount) - Number(selectedOffer?.funded_amount))}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                    <Divider />
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid marginBottom={'20px'} templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Total to pay</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.repayment_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.repayment_amount)}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                </Stack>
                            </Box>
                        </Card>

                        <Image src={approvalcard}></Image>

                        <Button isDisabled={!selectedOffer} onClick={() => { props.setOfferId(selectedOffer); props.setPage(Page.Contract); window.scrollTo(0, 0); }} fontWeight={'400'} bg={primary} color={selectedOffer ? 'white' : '#0E0B23'} borderRadius={'25px'} h='35px'>Confirm and continue</Button>
                        <Image marginTop={'20%'} src={footer} />

                    </Stack >
                }

            </Container >
        </Box>
    )
}

const Bars = (props: { selectedOffer?: Offer, config?: JourneyConfig }) => {
    const primary = props.config?.primary ?? '#6437E2';


    return (
        <Box h={'250px'}>
            <Stack direction={'row'}>
                <Box>
                    <Box key={props.selectedOffer?.offer_id} marginLeft={'60px'} className="barmed">
                        <Box h={`${100 - Number(props.selectedOffer?.split_percentage)}%`} bg={primary}></Box>
                        <Box h={`${Number(props.selectedOffer?.split_percentage)}%`} marginTop={'5px'} className="split"></Box>
                    </Box>
                    <Box pos={'absolute'} fontSize={'14px'} left={'18.5%'} top={'31%'}>
                        <Text>Day 1</Text>
                        <Text color={'gray'}>{formatCurrency(props.selectedOffer?.currency, 900)}</Text>
                    </Box>

                    <Box key={props.selectedOffer?.repayment_amount} className="fade-in-text-2" fontSize={'12px'} fontWeight={'300'} color={'black'} position={'absolute'} top='17%' left={'27%'}>{formatCurrency(props.selectedOffer?.currency, props.selectedOffer?.split_percentage ? ((900 / 100) * props.selectedOffer.split_percentage).toFixed(0) : 0)}</Box>
                </Box>

                <Box>
                    <Box key={props.selectedOffer?.offer_id} marginLeft={'0px'} className="barsmall">
                        <Box h={`${100 - Number(props.selectedOffer?.split_percentage)}%`} bg={primary}></Box>
                        <Box h={`${Number(props.selectedOffer?.split_percentage)}%`} marginTop={'5px'} className="split"></Box>
                    </Box>
                    <Box pos={'absolute'} fontSize={'14px'} left={'37.5%'} top={'31%'}>
                        <Text>Day 2</Text>
                        <Text color={'gray'}>{formatCurrency(props.selectedOffer?.currency, 600)}</Text>
                    </Box>

                    <Box key={props.selectedOffer?.repayment_amount} className="fade-in-text-2" fontSize={'12px'} fontWeight={'300'} color={'black'} position={'absolute'} top='20%' left={'45%'}>{formatCurrency(props.selectedOffer?.currency, props.selectedOffer?.split_percentage ? ((600 / 100) * props.selectedOffer.split_percentage).toFixed(0) : 0)}</Box>
                </Box>


                <Box>
                    <Box key={props.selectedOffer?.offer_id} marginLeft={'0px'} className="bar" >
                        <Box h={`${100 - Number(props.selectedOffer?.split_percentage)}%`} bg={primary}></Box>
                        <Box h={`${Number(props.selectedOffer?.split_percentage)}%`} marginTop={'5px'} className="split"></Box>

                    </Box>
                    <Box textAlign={'center'} pos={'absolute'} fontSize={'14px'} left={'53%'} top={'29%'}>
                        <Text fontSize={'12px'} margin={'-20px 0 20px 0'} color={'gray'}>No payment</Text>
                        <Text>Day 3</Text>
                        <Text color={'gray'}>No sales</Text>
                    </Box>
                </Box>


                <Box>
                    <Box key={props.selectedOffer?.offer_id} marginLeft={'0px'} h={'120px'} className="barlarge" >
                        <Box h={`${100 - Number(props.selectedOffer?.split_percentage)}%`} bg={primary}></Box>
                        <Box h={`${Number(props.selectedOffer?.split_percentage)}%`} marginTop={'5px'} className="split"></Box>
                    </Box>
                    <Box pos={'absolute'} fontSize={'14px'} left={'74.5%'} top={'31%'}>
                        <Text>Day 4</Text>
                        <Text color={'gray'}>{formatCurrency(props.selectedOffer?.currency, 1220)}</Text>
                    </Box>

                    <Box key={props.selectedOffer?.repayment_amount} className="fade-in-text-2" fontSize={'12px'} fontWeight={'300'} color={'black'} position={'absolute'} top='12.5%' left={'82%'}>{formatCurrency(props.selectedOffer?.currency, props.selectedOffer?.split_percentage ? ((1220 / 100) * props.selectedOffer.split_percentage).toFixed(0) : 0)}</Box>
                </Box>
            </Stack>
        </Box>
    )
}

const Thankyou = (props: { offer?: Offer, config?: JourneyConfig }) => {
    const primary = props.config?.primary ?? '#6437E2';

    const steps = [
        { title: 'Your application has been submitted!', description: 'One of our finance experts will reach out to discuss your application.' },
        { title: 'Funds reach your account', description: 'Typically once approved, you should receive funds within 24 - 48 hours.' },
        { title: 'Top up when you need', description: 'Once you’ve paid 50%, you’ll be able to renew to get more finance when you need it.' },
    ]

    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
    })

    return (<Box>
        <Container marginTop={'2%'} maxW={'5xl'}>
            <ProgressHeader step={3} percentage={100} config={props.config} />
            <Stack marginTop={'5%'} spacing={5}>
                <Heading size={'lg'}>Your application has been submitted!</Heading>
                <Text w='60%'>Your Revenue Finance application has been submitted and once approved will be paid out within 48 hours*.
                    You can check back on your funding overview to keep track of your application status.</Text>
            </Stack>
            <Grid marginTop={'50px'} templateColumns='repeat(2, 1fr)' gap={'40px'}>
                <GridItem colSpan={1}>
                    <Card h='400px'>
                        <CardBody>
                            <Stack spacing={10}>
                                <Heading size={'md'}>Application details</Heading>
                                <Text fontSize={'14px'} color={'#404A70'}>Here are the details of your submitted deal</Text>

                                <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                    <GridItem colSpan={2}>
                                        <Text>Percentage you pay</Text>
                                    </GridItem>
                                    <GridItem textAlign={'right'} colSpan={2}>
                                        <Text fontWeight={'bold'}>{props.offer?.split_percentage ? `${props.offer?.split_percentage}% daily sales` : '??'}</Text>
                                    </GridItem>
                                </Grid>

                                <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                    <GridItem colSpan={2}>
                                        <Text>Funding amount</Text>
                                    </GridItem>
                                    <GridItem textAlign={'right'} colSpan={2}>
                                        <Text fontWeight={'bold'}>{props.offer?.funded_amount ? `${formatCurrency(props.offer.currency, props.offer?.funded_amount)}` : '??'}</Text>
                                    </GridItem>
                                </Grid>

                                <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                    <GridItem colSpan={2}>
                                        <Text>Fixed fee</Text>
                                    </GridItem>
                                    <GridItem textAlign={'right'} colSpan={2}>
                                        <Text fontWeight={'bold'}>{props.offer?.funded_amount ? `${formatCurrency(props.offer.currency, (Number(props.offer?.repayment_amount) - Number(props.offer.funded_amount)))}` : '??'}</Text>
                                    </GridItem>
                                </Grid>
                            </Stack>
                        </CardBody>
                        <Box padding={5} bg='#ECEDF1'>
                            <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                <GridItem colSpan={2}>
                                    <Text>Total to pay</Text>
                                </GridItem>
                                <GridItem textAlign={'right'} colSpan={2}>
                                    <Text fontWeight={'bold'}>{props.offer?.repayment_amount ? `${formatCurrency(props.offer.currency, props.offer.repayment_amount)}` : '??'}</Text>
                                </GridItem>
                            </Grid>
                        </Box>
                    </Card>
                </GridItem>
                <GridItem colSpan={1}>
                    <Card>
                        <CardBody>
                            <Stack spacing={5}>
                                <Heading size={'md'}>What happens next?</Heading>
                                <Stepper index={activeStep} orientation='vertical' height='315px' gap='0'>
                                    {steps.map((step, index) => (
                                        <Step key={index}>
                                            <StepIndicator>
                                                <StepStatus
                                                    complete={<Box bg={primary} w='30px' h='30px' borderRadius={'20px'}><Center><StepIcon marginTop={'5px'} /></Center></Box>}
                                                    incomplete={<Box />}
                                                    active={<Box />}
                                                />
                                            </StepIndicator>
                                            <Box>
                                                <StepTitle><Text fontWeight={'bold'}>{step.title}</Text></StepTitle>
                                                <StepDescription>{step.description}</StepDescription>
                                            </Box>

                                            <StepSeparator />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>

        </Container>
    </Box>)
}


const ThankyouLatest = (props: { offer?: Offer, config?: JourneyConfig }) => {
    const primary = props.config?.primary ?? (props.config?.newJourney ? '#0E0B23' : '#6437E2');

    const steps = [
        { title: 'Application approved!', description: 'One of our finance experts will reach out to discuss your application.' },
        { title: 'Awaiting funds', description: 'Typically once approved, you should receive funds within 24 - 48 hours.' },
        { title: 'Funding payments begin', description: 'Once you’ve paid 50%, you’ll be able to renew to get more finance when you need it.' },
    ]

    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
    })

    return (<Box>
        <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>

        <Container marginTop={'2%'} maxW={'lg'}>
            <Stack spacing={10}>
                <Image margin={'0 auto'} src={progressfour} />

                <Text textAlign={'center'} margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Funding on its way</Text>
                <Text textAlign={'center'} fontWeight={'400'} color={'#404A70'} fontSize={'14px'}>Congratulations your quote has been approved and finance is on its way to your account.</Text>

                <Box p={5}>
                    <Stack spacing={10}>
                        <Stack spacing={5}>
                            <Text fontWeight={'700'} fontSize={'16px'}>What happens next?</Text>
                            <Text fontWeight={'400'} color={'#404A70'} size={'16px'}>Your application has been approved and funding is on it’s way. Check back soon to track payments.</Text>
                        </Stack>
                        <Stepper size={'sm'} colorScheme="green" index={activeStep} orientation='vertical' height='200px' gap='0'>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<Box bg={'#3FBE58'} borderRadius={'20px'}><Center><StepIcon h='22px' w='22px' /></Center></Box>}
                                            incomplete={<Box />}
                                            active={<Box />}
                                        />
                                    </StepIndicator>
                                    <Box>
                                        <StepTitle><Text fontSize={'16px'} color='#404A70' fontWeight={'400'}>{step.title}</Text></StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Box>
                <Button margin={'17% 0 5% 0'} onClick={() => { }} bg={primary} color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} h='35px' w={'100%'}>View my dashboard</Button>

            </Stack>
        </Container>
    </Box>)
}


const Contracts = (props: { setPage: any, offerId?: string, config?: JourneyConfig }) => {

    const [loading, setLoading] = useState<boolean>();
    const [contractLink, setContractLink] = useState<string>();
    const [frameHeight, setFrameHeight] = useState<number>(0)

    const config = useConfig()

    useEffect(() => {

        window.addEventListener(
            "message",
            function (event) {
                if (typeof event.data == 'string') {
                    try {
                        const message = JSON.parse(event.data);
                        if (message.containerHeight && message.containerHeight !== frameHeight) {
                            console.log(message.containerHeight);
                            setFrameHeight(message.containerHeight)
                        }
                        if (message.status === 'ContractComplete') {
                            props.setPage(Page.Thankyou)
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            },
            false,
        );

        const acceptOffer = async () => {
            setLoading(true);
            const token = await axios.post(`${config?.api.base}/token`, {
                client_id: config?.api.credentials.client_id,
                client_secret: config?.api.credentials.client_secret,
                grant_type: config?.api.credentials.grant_type,
            })

            const res = await axios.post(`${config?.api.base}/offers/${props.offerId}/accept`, null, { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
            if (res.data) {
                setContractLink(res.data.links.contract_link);
                console.log(res.data);
                setLoading(false);
            }
        }

        const contractLink = localStorage.getItem('contractlink');
        if (!contractLink) {
            acceptOffer();
        } else {
            setContractLink(contractLink);
        }
    }, [])

    return (
        <Box>
            <Container marginTop={'2%'} maxW={'5xl'}>
                <ProgressHeader step={2} percentage={75} config={props.config} />
                {loading && <Center><Text>Loading your contract...</Text></Center>}
                {!loading && <Box w='100%'>
                    {frameHeight === 0 && <Center><Spinner marginTop={'50px'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#905BFF'
                        size='xl'
                    />
                    </Center>}
                    <iframe scrolling="no" height={frameHeight} width={'100%'} src={contractLink}></iframe>
                </Box>}
            </Container>
        </Box >)
}

const ContractsLatest = (props: { setPage: any, offer?: Offer, config?: JourneyConfig }) => {
    const primary = props.config?.primary ?? (props.config?.newJourney ? '#0E0B23' : '#6437E2');
    const [loading, setLoading] = useState<boolean>();
    const [contractLink, setContractLink] = useState<string>();
    const [frameHeight, setFrameHeight] = useState<number>(0)
    const [viewContract, setViewContract] = useState<boolean>(false)
    const [contractSigned, setContractSigned] = useState<boolean>(false)

    const config = useConfig()

    useEffect(() => {

        window.addEventListener(
            "message",
            function (event) {
                if (typeof event.data == 'string') {
                    try {
                        const message = JSON.parse(event.data);
                        if (message.containerHeight && message.containerHeight !== frameHeight) {
                            console.log(message.containerHeight);
                            setFrameHeight(message.containerHeight)
                        }
                        if (message.status === 'ContractComplete') {
                            setViewContract(false)
                        }
                        if (message.status === 'SigningComplete') {
                            setContractSigned(true);
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            },
            false,
        );

        const acceptOffer = async () => {
            setLoading(true);
            const token = await axios.post(`${config?.api.base}/token`, {
                client_id: config?.api.credentials.client_id,
                client_secret: config?.api.credentials.client_secret,
                grant_type: config?.api.credentials.grant_type,
            })

            const res = await axios.post(`${config?.api.base}/offers/${props.offer?.offer_id}/accept`, null, { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
            if (res.data) {
                setContractLink(res.data.links.contract_link);
                console.log(res.data);
                setLoading(false);
            }
        }

        const contractLink = localStorage.getItem('contractlink');
        if (!contractLink) {
            acceptOffer();
        } else {
            setContractLink(contractLink);
        }
    }, [])

    return (
        <Box>
            <Modal isCentered size={'3xl'} onClose={() => { setViewContract(false) }} isOpen={viewContract}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody p='0' minHeight={'473px'} minWidth={'480px'}>
                        {(frameHeight === 0) && <AbsoluteCenter textAlign={'center'}>
                            <Stack spacing={1}>
                                <Text margin={'0 auto'} fontSize={'18px'} fontWeight={'600'}>Loading your contract</Text>
                                <Spinner margin={'0 auto'} marginTop={'20px'} />
                            </Stack>
                        </AbsoluteCenter>}
                        <iframe scrolling="no" height={frameHeight} width={'100%'} src={contractLink}></iframe>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Container marginTop={'2%'} maxW={'lg'}>

                <Image margin={'0 auto'} src={progressthree} />

                <Text textAlign={'center'} margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Lastly, sign your contract</Text>
                <Text textAlign={'center'} fontWeight={'400'} color={'#404A70'} fontSize={'14px'}>You can review and sign your contract to secure your finance.</Text>

                <Card margin={'10% 0 10% 0'} borderRadius={'9px'} padding={'30px'} border='1px solid #9C9C9C'>
                    <Text fontWeight={'600'} fontSize={'16px'}>Revenue based Finance Agreement</Text>
                    <Text color={contractSigned ? '#3FBE58' : '#404A70'} fontWeight={'600'} fontSize={'14px'}>{contractSigned ? 'Document signed' : 'Not yet signed'}</Text>
                    {!contractSigned && <Button marginTop={'20px'} borderRadius={'15px'} fontWeight={'400'} fontSize={'13px'} bg='#0E0B23' w='170px' h='30px' color={'white'} onClick={() => { setViewContract(true) }}>Sign</Button>}
                    {contractSigned && <Box textAlign={'right'}><Button variant={"link"} marginTop={'20px'} borderRadius={'15px'} fontWeight={'600'} fontSize={'16px'} w='170px' h='30px' color={'#0E0B23'} onClick={() => { setViewContract(true) }}>Review agreement <ChevronRightIcon boxSize={'22px'} /></Button></Box>}

                    <CheckCircleIcon color={contractSigned ? '#3FBE58' : '#D9D9D9'} boxSize={'30px'} w='35px' pos={'absolute'} top='5' right='5' />
                </Card>
                <Stack>
                    <Card border={'1px solid #00000063'} borderRadius={'20px'} top={0} >

                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>

                            <Tabs margin={'0 auto'} variant='soft-rounded' colorScheme='gray'>
                                <Stack padding={'20px'} spacing={5}>
                                    <TabList textAlign={'center'}>
                                        <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >Review quote terms</Tab>
                                        <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >How does it work?</Tab>
                                    </TabList>
                                </Stack>
                                <TabPanels>
                                    <TabPanel p={'30px'}>
                                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                            <Text fontSize={'19px'} fontWeight={'400'} size='md'>Quote terms</Text>
                                            <Text fontWeight={'400'} fontSize={'15px'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                                        </Skeleton>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>



                        </Skeleton>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Payment terms</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 20px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Split (%)</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.split_percentage ? `${props.offer?.split_percentage}% of daily sales` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>

                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Estimated completion</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.term_length ? `${props.offer?.term_length} months` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Total funding amount</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 10px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Funding amount</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.funded_amount ? `${formatCurrency(props.offer.currency, props.offer.funded_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Fixed fee</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer ? `${formatCurrency(props.offer.currency, Number(props.offer?.repayment_amount) - Number(props.offer?.funded_amount))}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Divider />
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid marginBottom={'20px'} templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Total to pay</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.repayment_amount ? `${formatCurrency(props.offer.currency, props.offer.repayment_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                    </Card>

                    <Button marginTop={'15%'} isDisabled={!contractSigned} onClick={() => { props.setPage(Page.Thankyou); window.scrollTo(0, 0); }} fontWeight={'400'} bg={primary} color={props.offer ? 'white' : '#0E0B23'} borderRadius={'25px'} h='35px'>Submit application</Button>
                    <Image marginTop={'20%'} src={footer} />

                </Stack >
            </Container>
        </Box >)
}

const JourneyConfigurator = (props: { setPage: any, setConfig: any }) => {

    const [hasShareholderInfo, setHasShareholders] = useState<boolean>(false);
    const [hasTransactionInfo, setHasTransactions] = useState<boolean>(false);
    const [enableBankStatements, setEnableBankStatements] = useState<boolean>(false);
    const [newJourney, setNewJourney] = useState<boolean>(false);
    const [logoUrl, setLogoUrl] = useState<string>('liberislogonew.svg');
    const [partnerName, setPartnerName] = useState<string>();
    const [primary, setPrimary] = useState<string>();

    const partialsteps = [
        { title: 'Contact Details', description: 'A page for a merchant to confirm your contact records are up to date before sharing with Liberis.' },
        { title: 'Your offer', description: 'Used to show the merchant their offer, allowing them to customise and select a price. This page is usually shown immediately after calling the /create/v2/offers endpoint.' },
        { title: 'Contracts', description: 'Display the merchant contract by opening an iframe to our Click2Sign user experience.' },
        { title: 'Finish', description: 'Provide a review page for the merchant to view their offer and progress as the application is submitted.' },
    ]

    const fullsteps = [
        { title: 'Contact Details', description: 'A page for a merchant to confirm your contact records are up to date before sharing with Liberis.' },
        { title: 'Business Details', description: 'Gather more information about the merchant that you may not have on record but is required by Liberis.', addition: true },
        { title: 'Your offer', description: 'Used to show the merchant their offer, allowing them to customise and select a price. This page is usually shown immediately after calling the /create/v2/offers endpoint.' },
        { title: 'Contracts', description: 'Display the merchant contract by opening an iframe to our Click2Sign user experience.' },
        { title: 'Finish', description: 'Provide a review page for the merchant to view their offer and progress as the application is submitted.' },
    ]

    const steps: {
        title: string;
        description: string;
        addition?: boolean
    }[] = !hasShareholderInfo || !hasTransactionInfo ? fullsteps : partialsteps;


    const saveConfiguration = () => {
        const config: JourneyConfig = {
            hasShareholderInfo,
            hasTransactionInfo,
            logoUrl,
            primary,
            partnerName,
            enableBankStatements,
            newJourney
        }

        localStorage.setItem('journeyconfig', JSON.stringify(config));
        props.setConfig(config);
        props.setPage(Page.Landing);
    }

    return (
        <Container marginTop={'2%'} maxW={'5xl'}>
            <Grid templateColumns='repeat(2, 1fr)' gap={10}>
                <GridItem>
                    <Card borderRadius={'25px'}>
                        <CardHeader borderBottom={'1px solid #1119271F'}>
                            <EditIcon m={2} boxSize={'22px'} />
                            <Text>Configure your journey</Text>
                            <Text fontSize={'14px'} color={'#6C737F'}>
                                Provide styling and choose the variant of journey to test with.
                            </Text>
                        </CardHeader>
                        <CardBody minH={'390px'}>
                            <Stack spacing={4} marginTop={'15px'}>
                                <FormControl>
                                    <CustomInput type="text" placeholder="Partner A" label="Partner name" setField={setPartnerName} field={partnerName}></CustomInput>
                                </FormControl>
                                <FormControl>
                                    <CustomInput type="text" placeholder="Logo url" label="Logo url" setField={setLogoUrl} field={logoUrl}></CustomInput>
                                </FormControl>
                                <FormControl>
                                    <CustomInput type="text" placeholder="#6466f0" label="Primary colour" setField={setPrimary} field={primary}></CustomInput>
                                </FormControl>
                                <FormControl cursor={'pointer'} mt={4} onClick={(e) => setHasShareholders(!hasShareholderInfo)}>
                                    <Stack direction={'row'}>
                                        {hasShareholderInfo ? <Box paddingTop={'13px'} style={{ color: primary }}><Selected /></Box> : <Image
                                            w='24px'
                                            src={checkbox}
                                        ></Image>}
                                        <Text>I have information on all shareholders, including ownership percentages, addresses etc.</Text>
                                    </Stack>
                                </FormControl>
                                <FormControl cursor={'pointer'} mt={4} onClick={(e) => setHasTransactions(!hasTransactionInfo)}>
                                    <Stack direction={'row'}>
                                        {hasTransactionInfo ? <Box paddingTop={'13px'} style={{ color: primary }}><Selected /></Box> : <Image
                                            w='24px'
                                            src={checkbox}
                                        ></Image>}
                                        <Text>  I have granular daily or monthly aggregated <b>revenue</b> data of my merchants.</Text>
                                    </Stack>
                                </FormControl>
                                <FormControl cursor={'pointer'} mt={4} onClick={(e) => setEnableBankStatements(!enableBankStatements)}>
                                    <Stack direction={'row'}>
                                        {enableBankStatements ? <Box paddingTop={'1px'} style={{ color: primary }}><Selected /></Box> : <Image
                                            w='24px'
                                            src={checkbox}
                                        ></Image>}
                                        <Text>Bank statements parsing (beta)</Text>
                                    </Stack>
                                </FormControl>
                                <FormControl cursor={'pointer'} mt={4} onClick={(e) => setNewJourney(!newJourney)}>
                                    <Stack direction={'row'}>
                                        {newJourney ? <Box paddingTop={'1px'} style={{ color: primary }}><Selected /></Box> : <Image
                                            w='24px'
                                            src={checkbox}
                                        ></Image>}
                                        <Text>Latest journey design (beta)</Text>
                                    </Stack>
                                </FormControl>
                                <Divider margin={'10px 0 10px 0'} />
                            </Stack>
                        </CardBody>
                        <CardFooter>
                            <Button mr={3} onClick={() => saveConfiguration()} color='white' w='100%' m={'0 0 10px 0'} bg="#6366F1">Start journey</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card borderRadius={'25px'}>

                        <CardHeader borderBottom={'1px solid #1119271F'}>
                            <InfoOutlineIcon m={2} boxSize={'22px'} />
                            <Text>Recommended user experience</Text>
                            <Text fontSize={'14px'} color={'#6C737F'}>
                                Based on your choices we recommend the following
                            </Text>
                        </CardHeader>
                        <CardBody >
                            <Stack spacing={2} marginTop={'15px'}>
                                <Stepper orientation='vertical' w={'100%'} colorScheme="purple" size={'md'} index={-1}>
                                    {steps.map((step, index) => (
                                        <Step key={index}>
                                            <StepIndicator>
                                                <StepStatus
                                                    complete={<StepIcon />}
                                                    incomplete={<Box></Box>}
                                                    active={<Box bg='#6437E2' w='100px' color='white' fontSize={'14px'} fontWeight={'bold'} textAlign={'center'} borderRadius={'100px'} h={'100%'}>...</Box>}
                                                />
                                                {step.addition && <AddIcon color={'green'} />}
                                            </StepIndicator>
                                            <Box>
                                                <StepTitle>{step.title}</StepTitle>
                                                <StepDescription>{step.description}</StepDescription>
                                            </Box>

                                            <StepSeparator />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid >

        </Container >)
}

const BankStatementImport = (props: { setPage: any, config?: JourneyConfig, setBankData: any }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const primary = props.config?.primary ?? '#6437E2';


    const handleFileChange = (acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    };
    const config = useConfig()

    const fileSizeValidator = (file: File) => {
        console.log(file.size)
        if (file.size > 10000000) {
            return {
                code: "file-too-large",
                message: `File is larger than 10mb`
            };
        }
        return null
    }

    const {
        getInputProps,
        getRootProps
    } = useDropzone({
        accept: {
            'application/pdf': [],
        },
        maxFiles: 1,
        validator: fileSizeValidator,
        noDragEventsBubbling: true,
        onDrop: handleFileChange
    });


    const handleFileUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        setLoading(true);
        try {
            const res = await axios.post(`${config?.api.base}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.data) {
                props.setBankData(res.data);
                props.setPage(Page.Review);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            <Container marginTop={'2%'} maxW={'5xl'}>
                <ProgressHeader step={1} percentage={20} />
                {!isLoading &&
                    <Stack spacing={5}>
                        <Heading size={'lg'}>Bank statement import</Heading>
                        <Text>Upload your bank statements to help us understand your business better.</Text>
                        <Box>
                            <Card margin={'0 auto'} border={'1px solid #dfe3e6'} shadow={'xl'} w='30rem'>
                                <CardBody>
                                    <Stack spacing={5}>
                                        <Center>
                                            <Card w={'90%'} cursor={'pointer'} textAlign={'center'} p='10' border={'2px dashed #E2E8F0'} boxShadow={'none'} {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <Stack spacing={4}>
                                                    <Center><DownloadIcon boxSize={'25px'} /></Center>
                                                    <Text>Drag and drop or <Text color="#905BFF">Choose file to upload</Text></Text>
                                                    <Text fontSize={'12px'}>PDF only</Text>
                                                </Stack>
                                            </Card>

                                        </Center>
                                        {selectedFile &&
                                            <Stack textAlign={'center'}>
                                                <Text>You have selected {selectedFile.name}<CheckCircleIcon boxSize={'16px'} color={'green.500'} m={'10px'} /></Text>
                                            </Stack>
                                        }
                                    </Stack>
                                </CardBody>
                            </Card>
                        </Box>
                        <Button isDisabled={!selectedFile} bg='#6437E2' margin={'0 auto'} w={'300px'} color='white' borderRadius={'25px'} h='50px' onClick={handleFileUpload}>Upload</Button>
                    </Stack>}

                {isLoading && <Stack spacing={8}>
                    <Center marginTop={'50px'}>
                        <Box position='absolute' className="circle"></Box>
                        <Box w='250px' color={primary}><Store /></Box>
                    </Center>
                    <Center marginTop={'50px'}>
                        <Heading size={'md'}>We're just reading your bank statement</Heading>
                    </Center>
                    <Center>
                        <Text>This might take up to a minute, we'll be back with your application shortly</Text>
                    </Center>
                </Stack>
                }
            </Container>
        </Box>


    )

}

const Review = (props: { setPage: any, config?: JourneyConfig, bankStatements?: BankStatementReport }) => {

    const primary = props.config?.primary ?? '#6437E2';
    console.log(props.config);

    const nextPage = !props.config?.hasTransactionInfo || !props.config?.hasShareholderInfo ? Page.BusinessInfo : Page.Offers

    const [contactConsent, setContactConsent] = useState<boolean>()
    const [renewalsConsent, setRenewalsConsent] = useState<boolean>()

    return (<Box>
        <Container marginTop={'2%'} maxW={'5xl'}>
            <ProgressHeader step={0} percentage={25} config={props.config} />
            <Grid templateColumns='repeat(5, 1fr)' gap={10}>
                <GridItem colSpan={2}>
                    <Stack spacing={10}>
                        <Heading>{props.bankStatements ? 'Your details' : 'Contact details'}</Heading>
                        <Text>Please take a moment to confirm your {props.bankStatements ? 'statement details' : 'contact details'}  are accurate before continuing.</Text>
                        <Card bg='#ECEDF1' borderTopRadius={'20px'}>
                            <CardHeader fontSize={'18px'} fontWeight={'400'} color={'white'} borderTopRadius={'20px'} bg={primary}>
                                <Text>Your details</Text>
                                <Text fontSize={'16px'} position={'absolute'} top={5} right={5}><EditIcon /> Edit</Text>
                            </CardHeader>
                            <CardBody>
                                {props.bankStatements &&
                                    <Stack spacing={5}>
                                        <FormControl>
                                            <FormLabel color='#404A70'>Name</FormLabel>
                                            <b>{props.bankStatements.merchant_names}</b>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color='#404A70'>Bank name</FormLabel>
                                            <b>{props.bankStatements.bank_names}</b>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color='#404A70'>Statement date</FormLabel>
                                            <b>{props.bankStatements.statement_start_date}</b>
                                        </FormControl>
                                        <FormLabel color='#404A70'>Your revenue</FormLabel>
                                        <Wrap>
                                            {props.bankStatements.revenue.map((revenue, index) => {
                                                return (
                                                    <WrapItem key={revenue.month}>
                                                        <Card w={'160px'}>
                                                            <CardBody>
                                                                <Stat>
                                                                    <StatLabel>{moment(revenue.month).format('MMM YYYY')}</StatLabel>
                                                                    <StatNumber fontSize={'16px'}> £{revenue.total}</StatNumber>
                                                                </Stat>

                                                            </CardBody>
                                                        </Card>
                                                    </WrapItem>
                                                )

                                            })}
                                        </Wrap>
                                    </Stack>}

                                {!props.bankStatements && <Stack spacing={5}>
                                    <FormControl>
                                        <FormLabel color='#404A70'>Name</FormLabel>
                                        <b>Maria Doe</b>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color='#404A70'>Email address</FormLabel>
                                        <b>maria.doe@outlook.com</b>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color='#404A70'>Contact phone number</FormLabel>
                                        <b>07392 1932739</b>
                                    </FormControl>
                                </Stack>
                                }
                            </CardBody>
                        </Card>

                        <Box border={`2px solid ${primary}`} p={10}>
                            <Text fontWeight={'bold'}>Credit Check: Read before continuing</Text>
                            <Text>By continuing with this application you understand that Liberis will share the information you submit with credit and fraud prevention agencies for the purposes of carrying out credit checks and fraud related searches on the business(es) and individual(s) referenced in this application. For more information on how your data will be used and shared please see <u>Liberis Privacy policy.</u>”
                            </Text>
                        </Box>

                        <Text>Please check this box if you are happy for Liberis to contact you, using the information you have provided, about all their products and their selected partners' products and services.</Text>
                        <Checkbox onChange={() => setContactConsent(!contactConsent)}>I consent</Checkbox>

                        <Text>Please uncheck this box if you <b>do not</b> want us to contact you using the information you have provided above about renewals finance or features of the revenue based product and services.</Text>
                        <Checkbox onChange={() => setRenewalsConsent(!renewalsConsent)}>I consent</Checkbox>

                        <Button isDisabled={!(contactConsent && renewalsConsent)} onClick={() => { props.setPage(props.config?.enableBankStatements ? Page.Offers : nextPage); window.scrollTo(0, 0); }} bg={primary} color={'white'} borderRadius={'25px'} h='50px'>Confirm and continue<ArrowForwardIcon marginLeft={'10px'} /></Button>

                    </Stack>
                </GridItem>
                <GridItem colSpan={3}>
                </GridItem>
            </Grid>
        </Container>
    </Box >)
}

const ReviewLatest = (props: { setPage: any, config?: JourneyConfig, bankStatements?: BankStatementReport }) => {
    const primary = props.config?.primary ?? (props.config?.newJourney ? '#0E0B23' : '#6437E2');

    console.log(props.config);

    const nextPage = !props.config?.hasTransactionInfo || !props.config?.hasShareholderInfo ? Page.BusinessInfo : Page.Offers

    const [viewBusiness, setViewBusiness] = useState<boolean>()
    const [viewPersonal, setViewPersonal] = useState<boolean>()

    return (<Box>
        <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>
        <Container marginTop={'2%'} maxW={'lg'}>


            <Stack spacing={10}>
                <Image margin={'0 auto'} src={progressone} />
                <Stack textAlign={'center'} >
                    <Text margin={'0 auto'} fontSize={'24px'} fontWeight={'600'}>First, lets check your details are all correct</Text>
                    <Text margin={'0 auto'} w={'90%'} fontSize={'14px'} fontWeight={'400'}>We’ll use the details below to calculate your offer and fees, which we’ll share with Liberis.</Text>
                </Stack>
            </Stack>

            <Box cursor={'pointer'} onClick={() => setViewBusiness(!viewBusiness)} pos={'relative'} marginTop={'10%'} p='25px' borderBottom={'1px solid #B3B7C6'}>
                <HStack>
                    <Image src={storecircle} />
                    <Text marginLeft={'25px'} fontWeight={'400'} color={'#404A70'} fontSize={'16px'}>Business details</Text>
                    {viewBusiness ? <ChevronDownIcon pos={'absolute'} right={10} boxSize={8} /> : <ChevronUpIcon pos={'absolute'} right={10} boxSize={8} />}
                </HStack>
            </Box>


            {viewBusiness && <Box padding={'30px'} bg={'#FAFAFA'}>
                <SimpleGrid columns={2}>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Business Entity</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Sole Trader</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Company name</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Terrace Cafe</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Business start date</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>04/2020</Text>
                        </Box>
                    </Stack>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Trading Address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>A12 B34</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Green Square</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Florida, UK</Text>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Box>}


            <Box cursor={'pointer'} onClick={() => setViewPersonal(!viewPersonal)} pos={'relative'} p='25px' borderBottom={'1px solid #B3B7C6'}>
                <HStack>
                    <Image src={personcircle} />
                    <Text marginLeft={'25px'} fontWeight={'400'} color={'#404A70'} fontSize={'16px'}>Your details</Text>
                    {viewPersonal ? <ChevronDownIcon pos={'absolute'} right={10} boxSize={8} /> : <ChevronUpIcon pos={'absolute'} right={10} boxSize={8} />}
                </HStack>
            </Box>
            {viewPersonal && <Box padding={'30px'} bg={'#FAFAFA'}>
                <SimpleGrid columns={2}>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Full name</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>John Doe</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Phone number</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>+44 07813 549385</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Email address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>john.doe@liberis.com</Text>
                        </Box>
                    </Stack>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Home Address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>A56 B78</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Blue Square</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>Florida, UK</Text>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Box>}


            <Box padding={'20px'}>
                <Text margin={'30px 0 10px 0'} fontWeight={'600'} fontSize={'14px'} color={'#404A70'}>Marketing</Text>
                <Text fontWeight={'400'} fontSize={'14px'} color={'#6C728A'}>
                    Please check this box if you are happy for Liberis to contact you, using the information you provided all about their products and their selected partners’ product and services.
                </Text>
                <Checkbox p={'20px 0 0 10px'}><Text fontSize={'14px'}>I consent</Text></Checkbox>

                <Button margin={'17% 0 5% 0'} onClick={() => { props.setPage((props.config?.enableBankStatements || props.config?.newJourney) ? Page.Offers : nextPage); window.scrollTo(0, 0); }} bg={primary} color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} h='35px' w={'100%'}>Get funding</Button>
                <Text textAlign={'center'} fontWeight={'400'} fontSize={'12px'} color={'#404A70'}>
                    By continuing, you accept that we’ll share your information with credit reference and fraud prevention agencies for security reasons. To see how your data will be used, please take a look at Liberis’ Privacy Policy.
                </Text>
            </Box>

            <Image marginTop={'20%'} src={footer} />

        </Container >
    </Box >)
}

const BusinessInfo = (props: { setPage: any, config?: JourneyConfig, currency: string }) => {
    const primary = props.config?.primary ?? '#6437E2';
    const [averageRevenue, setAverageRevenue] = useState<string>()
    const [sliderValue1, setSliderValue1] = useState<number>(50);
    const [sliderValue2, setSliderValue2] = useState<number>(50);

    return (<Box>
        <Container marginTop={'2%'} maxW={'5xl'}>
            <ProgressHeader step={0} percentage={25} config={props.config} />
            <Grid templateColumns='repeat(5, 1fr)' gap={10}>
                <GridItem colSpan={2}>
                    <Stack spacing={4}>
                        <Heading>Business details</Heading>
                        <Text>We need some more information from you to progress your finanace application.</Text>

                        {!props.config?.hasTransactionInfo &&
                            <Box>
                                <Divider marginBottom={'30px'} />
                                <FormControl>
                                    <FormLabel>Average monthly revenue</FormLabel>
                                    <Text marginBottom={'30px'} color={'gray'} fontSize={'14px'}>This number is the average revenue per month your business takes through your card terminal. We will validate this through a combination of bank statements and open banking.</Text>

                                    <InputGroup>
                                        <Input onChange={(e) => setAverageRevenue(e.target.value)} type="number" placeholder="5000"></Input>
                                    </InputGroup>
                                </FormControl>
                            </Box>
                        }
                        {!props.config?.hasShareholderInfo &&
                            <Stack marginTop={'30px'} spacing={10}>
                                <Heading size={'sm'}>Shareholder details</Heading>
                                <Text marginTop={'-20px'} marginBottom={'15px'} color={'gray'} fontSize={'14px'}>To progress your finance application, Liberis requires more information about your persons of significant control. Checks will be performed on all members.</Text>

                                <Card bg='#ECEDF1' borderTopRadius={'20px'}>
                                    <CardHeader fontSize={'18px'} fontWeight={'400'} color={'white'} borderTopRadius={'20px'} bg={primary}>
                                        <Text>Applicant 1</Text>
                                        <Text fontSize={'16px'} position={'absolute'} top={5} right={5}><EditIcon /> Edit</Text>
                                    </CardHeader>
                                    <CardBody>
                                        <Stack spacing={5}>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Name</FormLabel>
                                                <b>Maria Doe</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Email address</FormLabel>
                                                <b>maria.doe@outlook.com</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Contact phone number</FormLabel>
                                                <b>07392 1932739</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Ownership percentage</FormLabel>
                                                <Slider min={1} max={100} step={1} margin={'30px 0 0px 0'} onChange={(e) => setSliderValue1(e)} defaultValue={50} value={sliderValue1}
                                                >
                                                    <SliderTrack>
                                                        <SliderFilledTrack bg={primary} />
                                                    </SliderTrack>
                                                    <Tooltip
                                                        hasArrow
                                                        color='white'
                                                        placement='top'
                                                        isOpen={true}
                                                        label={`${sliderValue1}%`}
                                                    >
                                                        <SliderThumb bg={primary} boxSize={6}>
                                                            <Box bg={primary} />
                                                        </SliderThumb>
                                                    </Tooltip>

                                                </Slider>

                                            </FormControl>
                                        </Stack>
                                    </CardBody>
                                </Card>

                                <Card bg='#ECEDF1' borderTopRadius={'20px'}>
                                    <CardHeader fontSize={'18px'} fontWeight={'400'} color={'white'} borderTopRadius={'20px'} bg={primary}>
                                        <Text>Applicant 2</Text>
                                        <Text fontSize={'16px'} position={'absolute'} top={5} right={5}><EditIcon /> Edit</Text>
                                    </CardHeader>
                                    <CardBody>
                                        <Stack spacing={5}>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Name</FormLabel>
                                                <b>John Doe</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Email address</FormLabel>
                                                <b>john.doe@outlook.com</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Contact phone number</FormLabel>
                                                <b>07412 5938304</b>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel color='#404A70'>Ownership percentage</FormLabel>
                                                <Slider min={1} max={100} step={1} margin={'30px 0 0px 0'} onChange={(e) => setSliderValue2(e)} defaultValue={50} value={sliderValue2}
                                                >
                                                    <SliderTrack>
                                                        <SliderFilledTrack bg={primary} />
                                                    </SliderTrack>
                                                    <Tooltip
                                                        hasArrow
                                                        color='white'
                                                        placement='top'
                                                        isOpen={true}
                                                        label={`${sliderValue2}%`}
                                                    >
                                                        <SliderThumb bg={primary} boxSize={6}>
                                                            <Box bg={primary} />
                                                        </SliderThumb>
                                                    </Tooltip>

                                                </Slider>

                                            </FormControl>
                                        </Stack>
                                    </CardBody>
                                </Card>
                            </Stack>}


                        <Button marginTop={'100px'} isDisabled={!props.config?.hasTransactionInfo && !averageRevenue} onClick={() => { props.setPage(Page.Offers); window.scrollTo(0, 0); }} bg={primary} color={'white'} borderRadius={'25px'} h='50px'>Confirm and apply<ArrowForwardIcon marginLeft={'10px'} /></Button>

                    </Stack>
                </GridItem>
                <GridItem colSpan={3}>
                </GridItem>
            </Grid>
        </Container>
    </Box >)
}

const LandingLatest = (props: { setPage: any, config?: JourneyConfig, currency: string }) => {
    const primary = props.config?.primary ?? (props.config?.newJourney ? '#0E0B23' : '#6437E2');

    return (
        <Box>
            <Container marginTop={'5%'} maxW={'5xl'}>
                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    <GridItem w='100%'>
                        <Stack spacing={20}>
                            <Stack spacing={5}>
                                {props.config?.logoUrl && <Box w={'200px'} p={0}><Image src={props.config?.logoUrl}></Image></Box>}

                                {!props.config?.logoUrl && <Box bg={'#e0e0e0'} w={'200px'} p={3}>PARTNER LOGO</Box>}

                            </Stack>
                            <Stack spacing={5}>
                                <Text lineHeight={'60px'} fontSize={'48px'} fontWeight={'400'}>You’re pre-approved for up to {formatCurrency(props.currency, 40000)} in funding</Text>
                                <Text lineHeight={'24px'} fontWeight={'400'} fontSize={'18px'} color={'#667085'} w='80%'>Business finance made simple, unlock your potential today</Text>
                            </Stack>
                        </Stack>
                        <Stack textAlign={'center'} w='80%' marginTop={'10%'}>
                            <Button onClick={() => { props.setPage(props.config?.enableBankStatements ? Page.BankStatement : Page.Review); window.scrollTo(0, 0); }} bg={primary} color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} h='46px'>Access your quote</Button>
                            <Text fontWeight={'400'} color='#404A70' fontSize={'12px'}>For information on how we use your personal data, please see our <b><u>privacy policy</u></b></Text>
                        </Stack>


                    </GridItem>
                    <GridItem>
                        <Image marginTop={'20%'} src={merchantonphone}></Image>
                    </GridItem>
                </Grid>
            </Container>
            <Container marginTop={'10%'} maxW={'5xl'}>
                <SimpleGrid spacing={10} columns={2}>
                    <Image marginTop={'37%'} src={gauge} />
                    <SimpleGrid >

                        <Text marginBottom={'50px'} fontSize={'43px'} fontWeight={'400'}>Unlock Your Funding In 4 Quick Steps</Text>
                        <div></div>
                        <Card borderRadius={'18px'} border={'1px solid #E5E5E5'} boxShadow={'none'} marginBottom={'30px'} background={'#0E0B23'} p='20px'>
                            <Text fontSize={'20px'} fontWeight={'700'} color='white'>Verify your details</Text>
                            <Text fontSize={'14px'} fontWeight={'400'} color='#cfced3 '>Confirm your details are correct to receive the most accurate quotes.</Text>
                            <Text pos={'absolute'} fontSize={'30px'} top={'3'} right={'8'}>2    </Text>
                        </Card>
                        <div></div>
                        <Card borderRadius={'18px'} border={'1px solid #E5E5E5'} boxShadow={'none'} marginBottom={'30px'} p='20px'>
                            <Text fontSize={'20px'} fontWeight={'700'} color='#011821'>Get your personalised quote</Text>
                            <Text fontSize={'14px'} fontWeight={'400'} color='#6B6969'>Find the right payment terms for you with your quote breakdown calculated.</Text>
                            <Text pos={'absolute'} fontSize={'30px'} top={'3'} right={'8'}>2    </Text>
                        </Card>
                        <div></div>
                        <Card borderRadius={'18px'} border={'1px solid #E5E5E5'} boxShadow={'none'} marginBottom={'30px'} p='20px'>
                            <Text fontSize={'20px'} fontWeight={'700'} color='#011821'>Sign your contract</Text>
                            <Text fontSize={'14px'} fontWeight={'400'} color='#6B6969'>Review and sign your contract agreement</Text>
                            <Text pos={'absolute'} fontSize={'30px'} top={'3'} right={'8'}>3</Text>
                        </Card>
                        <div></div>
                        <Card borderRadius={'18px'} border={'1px solid #E5E5E5'} boxShadow={'none'} marginBottom={'30px'} p='20px'>
                            <Text fontSize={'20px'} fontWeight={'700'} color='#011821'>Funds on their way</Text>
                            <Text fontSize={'14px'} fontWeight={'400'} color='#6B6969'>If all approved you’ll receive your funds in 24 hours.**</Text>
                            <Text pos={'absolute'} fontSize={'30px'} top={'3'} right={'8'}>4</Text>
                        </Card>
                    </SimpleGrid>

                </SimpleGrid>

                <Stack marginTop={'15%'} textAlign={'center'}>
                    <Text marginBottom={'20px'} fontSize={'48px'} fontWeight={'400'}>Bridge a gap in your cashflow</Text>
                    <Text margin={'0 auto'} maxW={'70%'} color={'#667085'} fontSize={'16px'} fontWeight={'400'}>We know there’s no such such as business as usual, but you can be prepared for the unexpected whether it’s purchasing stock, paying bills or unforeseen expenses. No matter what happens you can keep things on track.</Text>
                </Stack>

                <Image marginTop={'15%'} src={merchantimage} />
                <Image marginTop={'15%'} src={explainers} />

                <Stack margin={'0 auto'} marginTop={'10%'} w='520px'>
                    <Button onClick={() => { props.setPage(props.config?.enableBankStatements ? Page.BankStatement : Page.Review); window.scrollTo(0, 0); }} bg={primary} color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} w='90%' h='46px'>Access your quote</Button>
                    <Text textAlign={'center'} maxW={'90%'} fontWeight={'400'} color='#404A70' fontSize={'12px'}>For information on how we use your personal data, please see our <b><u>privacy policy</u></b></Text>
                </Stack>

                <Image marginTop={'15%'} src={landingcard} />
                <Image marginBottom={'-20%'} marginTop={'15%'} src={questions} />

            </Container>
            <Box h='280px' marginRight={'-10px'} marginLeft={'-10px'} marginBottom={'-10%'} color={'white'} bg={'#0E0B23'}>
                <Container p={5} maxW={'4xl'}>
                    <Stack spacing={6}>
                        {props.config?.logoUrl && <Box w={'200px'} p={0}>[PARTNER LOGO]</Box>}
                        <Divider color={'white'} />
                        <Text fontWeight={'600'} fontSize={'14px'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Text>
                        <HStack>
                            <Text>Terms & Conditions</Text>
                            <Text>Privacy Policy</Text>
                        </HStack>
                    </Stack>
                </Container>
            </Box>
        </Box>)
}

const Landing = (props: { setPage: any, config?: JourneyConfig, currency: string }) => {
    const primary = props.config?.primary ?? '#6437E2';

    return (
        <Box>
            <Container marginTop={'5%'} marginBottom={'15%'} maxW={'5xl'}>
                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    <GridItem w='100%'>
                        <Stack spacing={10}>
                            {props.config?.logoUrl && <Box w={'200px'} p={0}><Image src={props.config?.logoUrl}></Image></Box>}

                            {!props.config?.logoUrl && <Box bg={'#e0e0e0'} w={'200px'} p={3}>PARTNER LOGO</Box>}
                            <Heading fontWeight={'600'}>You’re pre-approved for up to <Text color={primary}>{formatCurrency(props.currency, 40000)} </Text>with Revenue Finance*</Heading>
                            <Stack spacing={6}>
                                <Stack direction={'row'}>
                                    <Box color={primary}><Check /></Box>
                                    <Text>Fast, flexible finance from our trusted partner <b>Liberis</b>.</Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box color={primary}><Check /></Box>
                                    <Text>Apply in minutes and get a bespoke quote.</Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box color={primary}><Check /></Box>
                                    <Text>Get funded in 48 hours**</Text>
                                </Stack>
                            </Stack>
                            <Button onClick={() => { props.setPage(props.config?.enableBankStatements ? Page.BankStatement : Page.Review); window.scrollTo(0, 0); }} bg={primary} color={'white'} borderRadius={'25px'} w='270px' h='50px'>Get started <ArrowForwardIcon marginLeft={'10px'} /></Button>
                        </Stack>
                    </GridItem>
                    <GridItem>
                        <Image src={homeart}></Image>
                    </GridItem>
                </Grid>



            </Container>
            <Image pos={'absolute'} left={'-10px'} top={'89%'} w='110%' src={bgslide} zIndex={-1}></Image>
            <Container maxW={'5xl'}>
                <Stack spacing={'200px'}>
                    <Stack spacing={'50px'}>
                        <Heading fontWeight={'600'}>Why revenue based finance?</Heading>
                        <Center>
                            <Box color={primary}><Cards /></Box>
                        </Center>
                    </Stack>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem w='100%'>
                            <Stack spacing={5}>
                                <Heading fontWeight={'600'} color={'white'}>How does it work?</Heading>
                                <Text color={'white'}>Revenue Finance is a type of funding based on your revenue which is paid through your daily transactions. </Text>
                            </Stack>
                        </GridItem>
                        <GridItem w='100%'>
                            <Image src={video}></Image>
                        </GridItem>
                    </Grid>
                    <Stack spacing={'50px'}>
                        <Heading fontWeight={'600'} color={'white'}>Meet our customers</Heading>
                        <Center>
                            <Image w='70%' src={merchcards}></Image>
                        </Center>
                    </Stack>

                    <Stack spacing={'50px'}>
                        <Heading fontWeight={'600'} color={'white'}>Don’t let an opportunity pass you by</Heading>
                        <Text color={'white'}>Our customers use funding for a range of different activities to boost their businesses.</Text>

                        <Center>
                            <Image src={oppocards}></Image>
                        </Center>
                    </Stack>

                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <GridItem w='100%'>
                            <Stack spacing={5}>
                                <Heading fontWeight={'600'} color={'black'}>You’re pre-approved for up to <Text color={primary}>{formatCurrency(props.currency, 40000)}</Text> with Revenue Finance</Heading>
                                <Text color={'white'}>Revenue Finance is a type of funding based on your revenue which is paid through your daily transactions. </Text>
                                <Button onClick={() => { props.setPage(props.config?.enableBankStatements ? Page.BankStatement : Page.Review); window.scrollTo(0, 0); }} bg={primary} color={'white'} borderRadius={'25px'} w='270px' h='50px'>Get started <ArrowForwardIcon marginLeft={'10px'} /></Button>
                            </Stack>
                        </GridItem>
                        <GridItem w='100%'>
                            <Center>
                                <Image borderRadius={'20px'} src={customer}></Image>
                            </Center>
                        </GridItem>
                    </Grid>

                </Stack>


            </Container>
        </Box>)
}

export default ExampleJourney