import { Button, Card, Container, Heading, Stack, Image, Text, Center, CardHeader, CardBody, SimpleGrid, CardFooter, Grid, GridItem } from "@chakra-ui/react";
import axios from "axios";
import { FC, useContext } from "react";
import { AppContext } from "../App";
import create from '../create.svg'
import trust from '../trust.svg'
import money from '../money.svg'
import mobile from '../mobile.svg'
import checkcards from '../checkcards.svg'
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";

export const downloadCollection = async (url: string) => {
    const res = await axios.get(`${url}`);
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res.data));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "LiberisSandbox.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

const KnowledgeBasePage: FC = () => {

    const context = useContext(AppContext);
    const config = useConfig()

    return (<Container p='40px' maxWidth={'99%'}>
        <Heading color='#black' size={'lg'}>Knowledge base</Heading>
        <br></br>
        <SimpleGrid spacing={20} templateColumns='repeat(auto-fill, minmax(1fr))'>
            <Card textAlign={'center'} borderRadius={'20px'} boxShadow={'md'}>
                <CardHeader>
                    <Text fontSize='lg' size='md'>1. Download the test Postman collection.</Text>
                </CardHeader>
                <CardBody>
                    <Text>Use our prebuilt Postman collection to speed up your sandbox UI connections.</Text>
                </CardBody>
                <CardFooter>
                    <CardBody>
                        <Button onClick={() => window.open(`${config.postmanUrl}`, '_blank')} marginTop={'10px'} w='100%'>Download Postman <ExternalLinkIcon /></Button>
                        <Button onClick={() => { downloadCollection(config.postmanCollection) }} marginTop={'10px'} w='100%'>Download collection</Button>
                    </CardBody>
                </CardFooter>
            </Card>
            <Card textAlign={'center'} borderRadius={'20px'} boxShadow={'md'}>
                <CardHeader>
                    <Center>
                        <Image src={create}></Image>
                    </Center>
                    <Text fontSize='lg' as='h2' size='md'>How to apply for revenue based finance</Text>
                </CardHeader>
                <CardBody>
                    <Text>Learn how to create a merchant, apply for an advance, get and customise offers and ultimately get them funded!</Text>
                </CardBody>

                <CardFooter>
                    <CardBody>
                        <Button colorScheme="green"><Link to={'/creating-an-application'} >Start lesson</Link></Button>
                    </CardBody>
                </CardFooter>
            </Card>
            <Card textAlign={'center'} borderRadius={'20px'} boxShadow={'md'}>
                <CardHeader>
                    <Center>
                        <Image src={trust}></Image>
                    </Center>
                    <Text fontSize='lg' as='h2' size='md'>Test your partner data files and create merchants</Text>
                </CardHeader>
                <CardBody>
                    <Text>Learn about Gold Standard data, validate your files prior to sharing with Liberis in production, and create merchants for your end to end test scenarios. </Text>
                </CardBody>

                <CardFooter>
                    <CardBody>
                        <Button>Coming soon...</Button>
                    </CardBody>
                </CardFooter>
            </Card>
            <Card textAlign={'center'} borderRadius={'20px'} boxShadow={'md'}>
                <CardHeader>
                    <Center>
                        <Image src={mobile}></Image>
                    </Center>
                    <Text fontSize='lg' as='h2' size='md'>Market to your merchants using Liberis dashboard adverts</Text>
                </CardHeader>
                <CardBody>
                    <Text>Advertise to your merchants using Liberis marketing intelligence and dashboard advertisements.</Text>
                </CardBody>

                <CardFooter>
                    <CardBody>
                        <Button>Coming soon...</Button>
                    </CardBody>
                </CardFooter>
            </Card>
            <Card textAlign={'center'} borderRadius={'20px'} boxShadow={'md'}>
                <CardHeader>
                    <Center>
                        <Image src={money}></Image>
                    </Center>
                    <Text fontSize='lg' as='h2' size='md'>Applying for a renewal offer</Text>
                </CardHeader>
                <CardBody>
                    <Text>Learn when a merchant is eligible to renew, and how to allow them to top up their funding using the API.</Text>
                </CardBody>

                <CardFooter>
                    <CardBody>
                        <Button disabled>Coming soon...</Button>
                    </CardBody>
                </CardFooter>
            </Card>
        </SimpleGrid>
    </Container>)

}

const LessonCard = (props: { number: string, objective: string, description: string, actions: any }) => {
    return (<Card borderRadius={'20px'} h={'200px'} p={'30px'}>
        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            <GridItem w='100%' h='10' >
                <Stack direction={'row'}>
                    <Image src={checkcards}></Image>
                    <Stack>
                        <Text fontSize={'13px'} fontWeight={'500'} color={'#11192761'}>Objective</Text>
                        <Text fontSize={'36px'} fontWeight={'700'}>{props.number}</Text>
                        <Text>{props.objective}</Text>
                    </Stack>
                </Stack>

            </GridItem>
            <GridItem w='100%' h='10'  >
                <Stack>
                    <Text fontSize={'13px'} fontWeight={'500'} color={'#11192761'}>Description</Text>
                    <Text>{props.description}</Text>
                </Stack>
            </GridItem>
            <GridItem w='100%' h='10'  >
                <Stack>
                    <Text fontSize={'13px'} fontWeight={'500'} color={'#11192761'}>Quick start</Text>
                    {props.actions}
                </Stack></GridItem>
        </Grid>
    </Card>
    )
}

export default KnowledgeBasePage