import { ArrowDownIcon, CheckIcon, CloseIcon, ChevronDownIcon, ArrowForwardIcon, SettingsIcon, RepeatIcon, ArrowUpIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Tooltip, Text, useToast, Image, Container, Heading, TableContainer, Thead, Tr, Th, Tbody, Td, useColorModeValue, Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, StepSeparator, Stack, MenuButton, MenuList, MenuItem, Box, Button, Card, Divider, Menu, Skeleton, Table, AbsoluteCenter, Tag, TagLabel, TagLeftIcon, Checkbox, useColorMode, Link, Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverFooter, PopoverHeader } from "@chakra-ui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency, CopyButton, PaymentSetupModal, AcceptOfferModal, GetOffersModal, Merchant, Application, Deal, Page, Webhook, ApplicationDataWebhook, ApplicationStatusWebhook, ContractModal, CreatePaymentModal, DealRepaidModal, DealStatusModal, TestWebhookModal, ViewPaymentsModal, ViewWebhook, ViewDealModal, CardContainer, DeleteMerchantModal, ClaimsData, ViewPrequalifiedDataModal, ProductType, GetFlexiOffersModal, FlexiApplication, FlexiApplicationStatusWebhook, FlexiApplicationDataWebhook, ViewAccountModal, FlexiCreatePaymentModal, AccountStatusModal, AccountRepaidModal, GetFlexiAdvanceModal, FlexiAdvanceDataWebhook, FlexiAdvanceStatusWebhook } from "../App";
import cross from '../cross.svg'
import { useConfig } from "../hooks/useConfig";
import { FlexiAccount } from "../models/account";
import { FlexiAdvance } from "../models/advance";

const FlexiMerchant = (props: { setPage: (page: Page) => void, scopedMerchant?: Merchant, userId?: string, connectionLost: any }) => {
    const config = useConfig();
    const [loading, setLoading] = useState<boolean>(true);
    const [applicationDataModal, setApplicationDataModal] = useState<boolean>(false);
    const [getOffersModalOpen, setGetOffersModalOpen] = useState<boolean>(false);
    const [time, setTime] = useState(0);
    const [initialised, setInitialised] = useState<boolean>();

    const [merchant, setMerchant] = useState<Merchant>();
    const [applications, setApplications] = useState<FlexiApplication[]>();
    const [accounts, setAccounts] = useState<FlexiAccount[]>();
    const [advances, setAdvances] = useState<FlexiAdvance[]>();
    const [revenue, setRevenue] = useState<ClaimsData>();

    const [selectedAccount, setSelectedAccount] = useState<FlexiAccount>();
    const [selectedAdvance, setSelectedAdvance] = useState<FlexiAdvance>();
    const [latestAccount, setLatestAccount] = useState<FlexiAccount>();
    const [selectedApplication, setSelectedApplication] = useState<FlexiApplication>();

    const [webhooks, setWebhooks] = useState<Webhook[]>();
    const [dataModalOpen, setDataModal] = useState<boolean>(false);
    const [applicationStatusModalOpen, setApplicationStatusModal] = useState<boolean>(false);

    const [dealStatusModalOpen, setDealStatusModal] = useState<boolean>(false);
    const [dealDataModalOpen, setDealDataModal] = useState<boolean>(false);
    const [paymentModalOpen, setPaymentModal] = useState<boolean>(false);
    const [advanceCreatedModalOpen, setAdvanceCreatedModal] = useState<boolean>(false);
    const [viewPaymentModalOpen, setViewPaymentModal] = useState<boolean>(false);
    const [dealRepaidModalOpen, setDealRepaidModal] = useState<boolean>(false);
    const [advanceStatusModalOpen, setAdvanceStatusModal] = useState<boolean>(false);
    const [advanceDataModalOpen, setAdvanceDataModal] = useState<boolean>(false);

    const [viewPrequalModalOpen, setViewPrequalModal] = useState<boolean>(false);
    const [selectedChannel, setSelectedChannel] = useState<string>();

    const [acceptModalOpen, setAcceptModal] = useState<boolean>(false);

    const [webhookModal, setWebhookModal] = useState<boolean>(false);
    const [testWebhookModal, setTestWebhookModal] = useState<boolean>(false);
    const [selectedWebhook, setSelectedWebhook] = useState<Webhook>()

    const [redirectUrl, setRedirectUrl] = useState<string>();

    const [contractModal, setContractModal] = useState<boolean>(false);

    const [deleteMerchantModal, setDeleteMerchantModal] = useState<boolean>(false);

    const [renewalFlow, setRenewalFlow] = useState<boolean>();

    const location = window.location.toString().replace('#', '/');
    const merchantId = new URL(location).searchParams.get('merchantId');

    const [resetsEnabled, setResetsEnabled] = useState<boolean>(false);
    const [resetPoint, setResetPoint] = useState<MerchantStage>()

    enum MerchantStage {
        Created = 0,
        ApplicationCreated = 1,
        ContractSigned = 2,
        PaymentSetup = 3,
        AdvanceCreated = 4,
        AdvanceOffersCreated = 4,
        PaymentsCreated = 5,
        AccountRepaid = 6
    }

    const toggleResetEnabled = (enabled: boolean) => {
        if (enabled) {
            updateResetStage(MerchantStage.ApplicationCreated);
        }
        if (!enabled) {
            updateResetStage(undefined);
            setResetPoint(undefined);
        }
        setResetsEnabled(enabled);
    }

    useEffect(() => {
        console.log(resetsEnabled)
        let merchantLoop: any;

        const getMerchant = async () => {
            try {
                const response = await axios.get(`${config?.api.base}${config?.api.control.merchant}?merchantId=${merchantId}&partner=${props.userId}`)

                if (!resetPoint) {
                    setResetPoint(response.data.merchant.resetStage)
                }

                if (!resetsEnabled) {
                    setResetsEnabled(!!response.data.merchant.resetStage)
                }

                setApplications(response.data.flexiApplications)
                setMerchant(response.data.merchant)
                setAccounts(response.data.flexiAccounts)
                setAdvances(response.data.flexiAdvances)
                setWebhooks(response.data.webhooks)
                setRevenue(response.data.revenue);
                setRedirectUrl(response.data.redirect);


                const sortedApplications = response.data.flexiApplications.sort((a: any, b: any) => {
                    return new Date(a.data.createdAt).getTime() - new Date(b.data.createdAt).getTime();
                });

                const sortedAccounts = response.data.flexiAccounts.sort((a: any, b: any) => {
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                });

                const sortedAdvances = response.data.flexiAdvances.sort((a: any, b: any) => {
                    return new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime();
                });

                if (selectedApplication) {
                    setSelectedApplication(sortedApplications.find((x: FlexiApplication) => x.id === selectedApplication?.id))
                }
                else {
                    setSelectedApplication(sortedApplications[sortedApplications?.length - 1])
                }

                setLatestAccount(sortedAccounts[sortedAccounts?.length - 1]);

                if (selectedAccount) {
                    setSelectedAccount(sortedAccounts.find((x: FlexiAccount) => x.id === selectedAccount?.id))
                }
                else {
                    setSelectedAccount(sortedAccounts[sortedAccounts?.length - 1])
                }

                if (selectedAdvance) {
                    setSelectedAdvance(sortedAdvances.find((x: FlexiAdvance) => x.id === selectedAccount?.id))
                }
                else {
                    setSelectedAdvance(sortedAdvances[sortedAdvances?.length - 1])
                }

                setInitialised(true);
            }
            catch (e) {
                console.log(e);
                props.connectionLost()
            }
        }

        merchantLoop = setTimeout(() => {
            setTime(time + 1);
            getMerchant();
            setLoading(false);
        }, 2000);

        return () => {
            clearTimeout(merchantLoop);
        }
    }, [time, setLoading, selectedApplication, setSelectedApplication, selectedAccount, setSelectedAccount, merchantId, props])


    const deleteApplication = async (merchantId?: string) => {
        await axios.delete(`${config.api.base}${config.api.control.application}?merchantId=${merchantId}`)
    }

    const deleteDeal = async (dealId?: string) => {
        await axios.delete(`${config.api.base}${config.api.control.deal}?id=${dealId}`)

        toast({
            title: `Deleting deal ${dealId}`,
            description: "Deleting a deal also reverts the merchant and related application",
            status: 'warning',
            duration: 4000,
            isClosable: true
        })
    }

    const updateResetStage = async (stage?: MerchantStage) => {
        await axios.patch(`${config.api.base}${config.api.control.resetStage}`, { stage, merchantId })
    }

    const resetMerchant = async (stage?: MerchantStage) => {
        await axios.post(`${config.api.base}${config.api.control.refresh}`, { merchantId })
    }

    const toast = useToast();


    const steps = [
        { stage: MerchantStage.Created, title: 'Merchant Created', description: '', onClick: (): any => { } },
        { stage: MerchantStage.Created, title: 'Application Created', description: '', onClick: (): any => { setGetOffersModalOpen(true); setRenewalFlow(false) } },
        { stage: MerchantStage.ContractSigned, title: 'Contract Signed', description: '', onClick: (): any => { setContractModal(true) } },
        { stage: MerchantStage.PaymentSetup, title: 'Payment Setup', description: '', onClick: (): any => { } },
        { stage: [MerchantStage.AdvanceCreated, MerchantStage.AdvanceOffersCreated], title: 'Advance Created', description: '', onClick: (): any => { setAdvanceCreatedModal(true) }, block: true },
        { stage: MerchantStage.PaymentsCreated, title: 'Payments Created', description: '', onClick: (): any => { setPaymentModal(true) } },
        { stage: MerchantStage.AccountRepaid, title: 'Account Repaid / Closed', description: '', onClick: (): any => { setDealRepaidModal(true) } }
    ]

    const stepsToShow = steps;


    const { colorMode } = useColorMode()
    const navigate = useNavigate();

    return <Container paddingTop='20px' maxW={'8xl'}>
        <Box h='100%' padding={'30px'}>
            <Stack spacing={'10px'} marginBottom={'50px'}>
                <Stack direction={'row'} pos={'relative'}>
                    <Heading as='h4' size='lg'>Merchant Testing</Heading>
                    <Box pos={'absolute'} right={'0'}>
                        <Popover>
                            <PopoverTrigger>
                                <Button isDisabled={!initialised} className="createButton" fontSize={'14px'} borderRadius={'12px'} bg={'#6366F1'} color='white'>Launch an example journey <ArrowDownIcon marginLeft={'10px'} /></Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent width={'250px'}>
                                    <PopoverArrow />
                                    <PopoverHeader>Core</PopoverHeader>
                                    <PopoverBody>

                                        <Button w='225px' isDisabled={!initialised} className="createButton" fontSize={'14px'} borderRadius={'12px'} bg={'#6366F1'} color='white' onClick={() => window.open(
                                            `${redirectUrl}?external_id=${merchantId}`,
                                            '_blank' // <- This is what makes it open in a new window.
                                        )}><Text>Redirect to Core journey</Text><ExternalLinkIcon marginLeft={'10px'} /></Button>
                                    </PopoverBody>

                                    <PopoverHeader>Create</PopoverHeader>
                                    <PopoverBody>
                                        <Button w='225px' marginBottom={'5px'} isDisabled={!initialised} className="createButton" fontSize={'14px'} borderRadius={'12px'} bg={'#6366F1'} color='white' onClick={() => navigate(`/journey?merchantId=${merchantId}&currency=${merchant?.currency}`)}><Text>Start example journey <ArrowForwardIcon /></Text></Button>
                                        <Button w='225px' isDisabled={!initialised} className="createButton" fontSize={'14px'} borderRadius={'12px'} bg={'#6366F1'} color='white' onClick={() => { navigate(`/journey?merchantId=${merchantId}&currency=${merchant?.currency}`); localStorage.clear() }}><Text>Configure example journey </Text> <SettingsIcon marginLeft={'5px'} /></Button>
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                        <Button isDisabled={!initialised} marginLeft={'10px'} className="createButton" fontSize={'14px'} borderRadius={'12px'} color='black' onClick={() => { resetMerchant() }}><Text><RepeatIcon /> Reset merchant data</Text></Button>

                    </Box>
                </Stack>
                <Text>Review a merchants current state and test their application flow.</Text>
            </Stack>
            <Stack spacing={10}>

                <CardContainer title={`Merchant: ${merchant?.merchantId ?? ''}`}
                    subheading={
                        initialised && <Box>
                            <Text>Created: {merchant?.created?.toString().split('T')[0]}</Text>
                            <Text>{`Liberis id: ${selectedApplication?.liberis_id}`}
                                {selectedApplication?.liberis_id && <CopyButton text={selectedApplication?.liberis_id} />}
                            </Text>
                        </Box>}
                    children={<Box h='100%'>

                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Merchant Id</Th>
                                            <Th>Product</Th>
                                            <Th>Maximum offer</Th>
                                            <Th>Decision</Th>
                                            <Th>Webhook configured</Th>
                                            <Th>Created</Th>
                                            <Th>Delete</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{merchant?.merchantId}</Td>
                                            <Td>{merchant?.product}</Td>
                                            <Td>{formatCurrency(merchant?.currency, merchant?.limits.maximum)}</Td>
                                            <Td>{merchant?.decision}</Td>
                                            <Td>{merchant?.webhookUrl ? <Tag size={'sm'} variant='subtle' colorScheme='green'>
                                                <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                <TagLabel>Configured</TagLabel>
                                            </Tag> :
                                                <Tag size={'sm'} variant='subtle' colorScheme='red'>
                                                    <TagLeftIcon boxSize='12px' as={CloseIcon} />
                                                    <TagLabel>Not configured</TagLabel>
                                                </Tag>}</Td>
                                            <Td>{merchant?.created?.toString().split('T')[0]}</Td>
                                            <Td><Image src={cross} color='crimson' cursor={'pointer'} onClick={(e) => { setDeleteMerchantModal(true) }} /></Td>
                                        </Tr>

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box>} />

                <CardContainer title="Merchant progress" subheading="Click on the steps below to view progress" children={<Box>
                    <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                        <Box minH={'40px'}>
                            <Box float={'right'} marginTop={'-50px'} >
                                {!resetsEnabled &&
                                    <Button fontWeight={'light'} color={'green'} marginRight={'40px'} size='sm' variant={'link'} onClick={() => toggleResetEnabled(true)}>
                                        Auto reset
                                    </Button>
                                }
                                {resetsEnabled &&
                                    <Button fontWeight={'light'} color={'red'} marginRight={'40px'} size='sm' variant={'link'} onClick={() => toggleResetEnabled(false)}>
                                        Cancel reset
                                    </Button>
                                }
                            </Box>
                            {applications?.length === 0 &&
                                <Box>
                                    <Card boxShadow={'none'} borderRadius={'20px'}>
                                        <Stepper colorScheme="purple" size='md' padding={'20px'} index={1}>

                                            {stepsToShow.map((step, index) => (
                                                <>
                                                    <Step onClick={() => { step.onClick() }} key={step.title} style={{ cursor: 'pointer' }} >
                                                        <Step onClick={() => { }} style={{ cursor: 'pointer' }}>
                                                            <StepIndicator backgroundColor={'#6266f133 !important'}>
                                                                <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box bg='#6266f1' borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} active={
                                                                    <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                                        <AbsoluteCenter>
                                                                            <Box className="flexi-active" borderRadius={'50px'} w='20px' h='20px'>
                                                                            </Box>
                                                                        </AbsoluteCenter>
                                                                    </Box>} />
                                                            </StepIndicator>

                                                            <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                                <StepTitle><Text fontSize={'14px'}>{step.title}</Text></StepTitle>
                                                                <StepDescription>{step.description}</StepDescription>
                                                            </Box>
                                                        </Step>
                                                    </Step>
                                                </>
                                            ))}
                                        </Stepper>
                                    </Card>
                                </Box>
                            }
                            {applications?.map((x, index) => {
                                return <Box key={x.id}>
                                    <Card boxShadow={'none'} border={'none'} borderRadius={'20px'}>
                                        <Stepper colorScheme="purple" size='md' padding={'20px'} index={MerchantStage[merchant?.stage as keyof typeof MerchantStage]}>
                                            {stepsToShow.map((step, index) => (
                                                <>
                                                    <Step onClick={() => { step.onClick(); setSelectedApplication(x); setSelectedAccount(accounts ? accounts[0] : undefined) }} key={step.title} style={{ cursor: 'pointer' }}>
                                                        <StepIndicator backgroundColor={'#6266f133 !important'}>
                                                            <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                <AbsoluteCenter>
                                                                    <Box bg='#6266f1' borderRadius={'50px'} w='20px' h='20px'>
                                                                    </Box>
                                                                </AbsoluteCenter>
                                                            </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                <AbsoluteCenter>
                                                                    <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                                    </Box>
                                                                </AbsoluteCenter>
                                                            </Box>} active={step.block && merchant?.decision === 'Declined' ? <CloseIcon /> :
                                                                <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box className="flexi-active" borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} />
                                                        </StepIndicator>

                                                        <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                            <StepTitle><Text className={index === MerchantStage[merchant?.stage as keyof typeof MerchantStage] ? 'activeText' : ''} fontSize={'14px'}>{step.title}</Text></StepTitle>
                                                            <StepDescription>{step.description}</StepDescription>
                                                        </Box>

                                                    </Step>
                                                </>
                                            ))}
                                        </Stepper>
                                        {
                                            index !== applications?.length - 1 &&
                                            <Card boxShadow={'none'} border={'none'} background={colorMode === 'light' ? 'rgb(250 250 250 / 70%)' : 'rgb(60 60 60 / 70%)'} position={'absolute'} width={'100%'} height={'100%'} zIndex={50}></Card>
                                        }
                                    </Card>

                                    {
                                        index !== applications?.length - 1 && <Box>
                                            <Stack direction='row' h='70px' p={6}>
                                                <Divider color={'black'} orientation='vertical' />
                                                <Divider paddingTop={'10px'}></Divider>
                                            </Stack>
                                        </Box>
                                    }

                                </Box>
                            })}
                        </Box>
                    </Skeleton>
                </Box >} />


                < CardContainer title={`Client and revenue claims`}
                    subheading={< Text >Any claims and revenue submitted via the <Link href='https://liberis-api-v2.readme.io/reference/create-partner-client' isExternal>Claims API<ExternalLinkIcon mx='2px' />
                    </Link> will be displayed here. Revenue submitted will affect the maximum offer amount for this test merchant.</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Company id</Th>
                                            <Th>Company start date</Th>
                                            <Th>Entity type</Th>
                                            <Th>Revenue claims</Th>
                                            <Th>Total revenue</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {revenue && <Tr cursor={'pointer'} key={revenue?.partner_client_id} onClick={() => { setSelectedChannel(revenue?.partner_client_id); setViewPrequalModal(true) }}>
                                            <Td>{revenue?.partner_client_id}</Td>
                                            <Td>{revenue?.client_start_date?.toString()}</Td>
                                            <Td>{revenue?.entity_type}</Td>
                                            <Td>{revenue?.revenue?.length ? revenue?.revenue?.length : ''}</Td>
                                            <Td>{revenue?.revenue?.reduce(
                                                (accumulator, currentValue) => accumulator + Number(currentValue.total_amount),
                                                0,
                                            )}</Td>
                                        </Tr>}

                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Applications`}
                    subheading={< Text > {`Current application id: ${selectedApplication?.id ?? 'Created upon offer generation'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Merchant Id</Th>
                                            <Th>Legal name</Th>
                                            <Th>Status</Th>
                                            <Th>Offer Amount</Th>
                                            <Th>Account created</Th>
                                            <Th>Type</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {applications?.map((x: FlexiApplication) => {
                                            return <Tr key={x?.id}>
                                                <Td>{x?.merchantId}</Td>
                                                <Td>{x?.company?.registeredName}</Td>
                                                <Td>{x?.status}</Td>
                                                <Td>{formatCurrency(merchant?.currency, merchant?.limits.maximum)}</Td>
                                                <Td>
                                                    {accounts && accounts.length > 0 ? <Tag size={'sm'} variant='subtle' colorScheme='green'>
                                                        <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                        <TagLabel>Created</TagLabel>
                                                    </Tag>
                                                        : <Tag size={'sm'} variant='subtle' colorScheme='red'>
                                                            <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                            <TagLabel>Not Created</TagLabel>
                                                        </Tag>}

                                                </Td>
                                                <Td>Flexi</Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                            Actions
                                                        </MenuButton>
                                                        <MenuList>
                                                            <MenuItem onClick={() => { setSelectedApplication(x); setApplicationStatusModal(true) }}>Change status</MenuItem>
                                                            <MenuItem onClick={() => { setSelectedApplication(x); setApplicationDataModal(true) }}>View data</MenuItem>
                                                            <MenuItem onClick={() => { deleteApplication(x?.merchantId) }}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        })}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Accounts`}
                    subheading={< Text > {`Current account id: ${selectedAccount?.id ?? 'Created upon offer generation'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Account Id</Th>
                                            <Th>Status</Th>
                                            <Th>Size</Th>
                                            <Th>Utilised</Th>
                                            <Th>Repayment</Th>
                                            <Th>Balance</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {accounts?.map((account: FlexiAccount) => {
                                            const utilised = advances?.reduce(
                                                (accumulator, currentValue) => accumulator + Number(currentValue.amount),
                                                0,
                                            );
                                            const repayment = advances?.reduce(
                                                (accumulator, currentValue) => accumulator + (Number(currentValue.amount) + Number(currentValue.fee)),
                                                0,
                                            );

                                            return (
                                                <Tr key={account.id}>
                                                    <Td>{account?.id}</Td>
                                                    <Td>{account?.status}</Td>
                                                    <Td>{formatCurrency(account?.application?.currency, account?.limits.size)}</Td>
                                                    <Td>{formatCurrency(account?.application?.currency, utilised)}</Td>
                                                    <Td>{formatCurrency(account?.application?.currency, repayment)}</Td>
                                                    <Td>
                                                        {formatCurrency(account?.application?.currency, account?.outstandingBalance)}
                                                    </Td>
                                                    <Td>
                                                        <Menu>
                                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                                Actions
                                                            </MenuButton>
                                                            <MenuList>
                                                                <MenuItem onClick={() => { setSelectedAccount(account); setViewPaymentModal(true) }}>View payments</MenuItem>
                                                                {account?.status === 'Funded' && <MenuItem onClick={() => { setSelectedAccount(account); setPaymentModal(true) }} >Add payment</MenuItem>}
                                                                <MenuItem onClick={() => { setSelectedAccount(account); setDealStatusModal(true) }} >Change status</MenuItem>
                                                                <MenuItem onClick={() => { setSelectedAccount(account); setDealDataModal(true) }} >View data</MenuItem>
                                                                <MenuItem onClick={() => { deleteDeal(account?.id) }}>Delete</MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>)
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />

                < CardContainer title={`Advances`}
                    subheading={< Text > {`Number of advances: ${advances && advances.length}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Advance Id</Th>
                                            <Th>Status</Th>
                                            <Th>Amount</Th>
                                            <Th>Fee</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {advances?.map((advance: FlexiAdvance) => {
                                            return (
                                                <Tr key={advance.id}>
                                                    <Td>{advance?.id}</Td>
                                                    <Td>{advance?.status}</Td>
                                                    <Td>{formatCurrency(advance.currency, advance.amount)}</Td>
                                                    <Td>{formatCurrency(advance.currency, advance.fee)}</Td>
                                                    <Td>
                                                        <Menu>
                                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                                Actions
                                                            </MenuButton>
                                                            <MenuList>
                                                                <MenuItem onClick={() => { setSelectedAdvance(advance); setAdvanceStatusModal(true) }} >Change status</MenuItem>
                                                                <MenuItem onClick={() => { setSelectedAdvance(advance); setAdvanceDataModal(true) }} >View data</MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>)
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Webhooks`}
                    subheading={< Text > {`${merchant?.webhookUrl ? `Webhook url: ${merchant?.webhookUrl}` : 'No webhook configuration set for merchant'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'20px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'20px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Created</Th>
                                            <Th>Merchant</Th>
                                            <Th>event</Th>
                                            <Th>Success</Th>
                                            <Th>Response code</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {webhooks?.map(x => {
                                            return <Tr key={x.id} cursor={'pointer'} onClick={() => { setSelectedWebhook(webhooks.filter((y: Webhook) => y.id === x.id)[0]); setWebhookModal(true) }}>
                                                <Td>{x.created.toString()}</Td>
                                                <Td>{x.merchantId}</Td>
                                                <Td>{x.event}</Td>
                                                <Td>{x.success ? <Tag size={'sm'} variant='subtle' colorScheme='green'>
                                                    <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                    <TagLabel>OK</TagLabel>
                                                </Tag> :
                                                    <Tag size={'sm'} variant='subtle' colorScheme='red'>
                                                        <TagLeftIcon boxSize='12px' as={CloseIcon} />
                                                        <TagLabel>FAILED</TagLabel>
                                                    </Tag>}</Td>
                                                <Td>{x.code}</Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />

                <FlexiAdvanceDataWebhook dataModalOpen={advanceDataModalOpen} setDataModal={setAdvanceDataModal} selectedAdvance={selectedAdvance} />
                <FlexiApplicationDataWebhook dataModalOpen={applicationDataModal} setDataModal={setApplicationDataModal} selectedApplication={selectedApplication} />
                <FlexiApplicationStatusWebhook statusModalOpen={applicationStatusModalOpen} setStatusModal={setApplicationStatusModal} selectedApplication={selectedApplication} connectionLost={props.connectionLost} />
                <FlexiAdvanceStatusWebhook statusModalOpen={advanceStatusModalOpen} setStatusModal={setAdvanceStatusModal} selectedAdvance={selectedAdvance} connectionLost={props.connectionLost} />
                <ViewAccountModal dataModalOpen={dataModalOpen} setDataModal={setDataModal} account={selectedAccount} />
                <FlexiCreatePaymentModal setPaymentModal={setPaymentModal} paymentModalOpen={paymentModalOpen} account={selectedAccount} userId={props.userId} connectionLost={props.connectionLost} />
                <AccountStatusModal statusModalOpen={dealStatusModalOpen} setStatusModal={setDealStatusModal} account={selectedAccount} connectionLost={props.connectionLost} />
                <ViewPaymentsModal viewPaymentModalOpen={viewPaymentModalOpen} setViewPaymentModal={setViewPaymentModal} data={selectedAccount} />
                <ViewPrequalifiedDataModal viewPrequalModalOpen={viewPrequalModalOpen} setViewPrequalModal={setViewPrequalModal} revenue={revenue} selectedChannel={selectedChannel} />
                <GetFlexiOffersModal getOffersModalOpen={getOffersModalOpen} setGetOffersModalOpen={setGetOffersModalOpen} merchant={merchant} isRenewal={renewalFlow} liberisId={selectedAccount?.liberis_id} />
                <GetFlexiAdvanceModal getAdvanceModalOpen={advanceCreatedModalOpen} setGetAdvanceModalOpen={setAdvanceCreatedModal} account={accounts && accounts[0]} merchant={merchant} isRenewal={renewalFlow} liberisId={selectedAccount?.liberis_id} />
                <AccountRepaidModal dealRepaidModalOpen={dealRepaidModalOpen} setDealRepaidModal={setDealRepaidModal} account={selectedAccount} />
                <ViewWebhook webhookModal={webhookModal} setWebhookModal={setWebhookModal} selectedWebhook={selectedWebhook} />
                <TestWebhookModal canRenew={accounts && accounts.length > 1} testWebhookModal={testWebhookModal} setTestWebhookModal={setTestWebhookModal} userId={props.userId} selectedMerchant={merchant} connectionLost={props.connectionLost} />
                <ViewAccountModal dataModalOpen={dealDataModalOpen} setDataModal={setDealDataModal} account={selectedAccount} />
                <ContractModal contractModal={contractModal} setContractModal={setContractModal} applicationId={selectedApplication?.id} currentContractStep={selectedApplication?.contractStep} />
                <DeleteMerchantModal deleteModalOpen={deleteMerchantModal} setDeleteModal={setDeleteMerchantModal} merchantId={merchant?.merchantId} />
            </Stack >
        </Box >

    </Container >
}

export default FlexiMerchant;