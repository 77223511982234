import { Text, Box, Card, ChakraProvider, Container, Heading, Stack, useColorModeValue, Center, useToast, Divider, Tabs, Tab, TabList, TabPanel, TabPanels } from "@chakra-ui/react";
import axios from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { Advert, Adverts, EditAdvert, EditRedirect } from "./TailoredMarketingPage";
import { useConfig } from "../hooks/useConfig";

const DefaultMarketingPage: FC = () => {

    const [advert, setAdvert] = useState<Advert>({
        header: "You could be eligible for up to ",
        title: "£300,000 for your business",
        subtitle: "Provide information about your revenue to see how much you can apply for!",
        body: "Paid into your business bank account once successfully funded by Liberis. Terms and Conditions apply.",
        footer: 'Terms and conditions apply',
        cta: "Get A Quote",
        redirectUrl: "https://google.com"
    })

    const { userId } = useContext(AppContext);
    const config = useConfig()

    const toast = useToast();

    useEffect(() => {
        const getAdvert = async () => {
            try {
                const res = await axios.get(`${config?.api.base}${config?.api.control.marketing}?partner=${userId}`)
                if (res.data.defaultAdvert) {
                    setAdvert(res.data.defaultAdvert)
                }
            }
            catch (e) {
                console.log(e);
            }
        }

        if (userId) {
            getAdvert();
        }
    }, [userId])



    const saveAdvert = async () => {
        try {
            await axios.post(`${config?.api.base}${config?.api.control.marketing}`, {
                partner: userId,
                defaultAdvert: advert
            })
            toast({
                title: 'Advert copy successfully updated',
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        }
        catch (e) {
            toast({
                title: 'Advert update failed',
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return <ChakraProvider>
        <Container maxW={'8xl'}>
            <Box maxW={'1xl'} padding={'5% 10% 0% 10%'}>
                <Heading size={'lg'}>Default adverts</Heading>
                <br></br>
                <Text> When Liberis can't find an offer for a merchant Id, we will return a default advert if configured. This is an opportunity to return a generic offer to target individuals outside the normal selection process.</Text>
            </Box>
            <Box maxW={'1xl'} padding={'5% 10% 5% 10%'}>
                <Center>
                    <Box
                        padding={''}
                        rounded={'sm'}
                        overflow={'hidden'}
                        border={'1px'}
                        borderColor="black"
                        bg={useColorModeValue('white', 'gray.600')}
                        boxShadow={useColorModeValue('6px 6px 0 black', '6px 6px 0 gray')}>
                        <Adverts advert={advert} />
                    </Box>
                </Center>
                <Center>
                    <Stack textAlign={'center'}>
                        <Heading marginTop={'20px'} size={'sm'}>Example banner advert</Heading>
                    </Stack>
                </Center>

            </Box>
            <Tabs variant='soft-rounded' colorScheme='purple'>
                <Heading size={'md'} m='0 10% 20px 11%'>Configuration</Heading>

                <TabList m='0 0% 0 12%'>
                    <Tab>Advert copy</Tab>
                    <Tab>Redirect url</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Card m='0 10% 0 10%'>
                            <EditAdvert disabled tailored={false} saveAdvert={saveAdvert} advert={advert} setAdvert={setAdvert} />
                        </Card>
                    </TabPanel>
                    <TabPanel>
                        <Card m='0 10% 0 10%'>
                            <EditRedirect disabled saveAdvert={saveAdvert} advert={advert} setAdvert={setAdvert} />
                        </Card>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    </ChakraProvider >
}

export default DefaultMarketingPage